import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import CustomList from '../../custom/CustomList';
import AuthContext from '../../../contexts/AuthContext';
import { CircularProgress } from "@mui/material";
import CustomTag from "../../custom/CustomTag";

const Facturation = () => {
    const [recurringLines, setRecurringLines] = useState([]);
    const [totalMois, setTotalMois] = useState(0);
    const [totalAbonnements, setTotalAbonnements] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const itemIdLivie = '180635000004769204';

    useEffect(() => {
        setIsLoaded(false);

        const fetchContacts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_UPA_API_HOST}books/contacts`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const rawData = response.data;
                const filteredLines = [];
                let totalMensuel = 0;
                let totalAbonnements = 0;

                rawData.forEach(contact => {
                    let hasSubscription = false;

                    if (contact.recurringLines && contact.recurringLines.length > 0) {
                        contact.recurringLines.forEach(line => {
                            if (line.item_id === itemIdLivie) {
                                const montantFacturation = parseFloat(line.item_total);
                                let montantMensuel = montantFacturation;

                                if (line.recurrence_frequency === 'months') {
                                    if (line.repeat_every === 3) {
                                        montantMensuel = montantFacturation / 3;
                                    }
                                } else if (line.recurrence_frequency === 'years') {
                                    montantMensuel = montantFacturation / 12;
                                }

                                filteredLines.push({
                                    contact_name: contact.data.contact_name,
                                    item_total: montantMensuel,
                                });

                                totalMensuel += montantMensuel;
                                hasSubscription = true;
                            }
                        });
                    }

                    if (hasSubscription) {
                        totalAbonnements += 1;
                    }
                });

                setRecurringLines(filteredLines);
                setTotalMois(totalMensuel);
                setTotalAbonnements(totalAbonnements);
                setIsLoaded(true);
            } catch (error) {
                console.error('Erreur lors de la récupération des contacts :', error);
                setIsLoaded(true);
            }
        };

        fetchContacts();
    }, [token]);

    const columns = React.useMemo(() => [
        {
            Header: "Nom du client",
            accessor: "contact_name",
            icon: "user",
            Cell: ({ value }) => value.toUpperCase(),
        },
        {
            Header: "Montant total",
            accessor: "item_total",
            icon: "euro",
            Cell: ({ value }) => <CustomTag value={`${value.toFixed(2)}€`} className="active" />,
        }
    ], []);

    return (
        <div>
            <div className="macro-total-wrapper">
                <div className="macro-total-content">
                    <span><strong>Nombre d'abonnements</strong>&nbsp;: {totalAbonnements}</span>
                </div>
                <span><strong>Total mensuel</strong>&nbsp;: {totalMois.toFixed(2)}€</span>
            </div>
            {isLoaded ? (
                <div className="view-container">
                    <div className="tabs-container">
                        <CustomList
                            columns={columns}
                            entity={recurringLines}
                            isSelectable={false}
                            isFetchable={false}
                            isClickable={false}
                            isDownloadable={false}
                            isFixed={false}
                        />
                    </div>
                </div>
            ) : (
                <div className='loader-container'><CircularProgress size={30} /></div>
            )}
        </div>
    );
};

export default Facturation;
