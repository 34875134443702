import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import Tests from "./tests/Tests";

const Hiring = () => {

    const moduleName = 'hiring';

    return (
        <Routes>
            <Route path="tests/*" element={<PermissionsGate module={moduleName} submodule='tests'><Tests /></PermissionsGate>} />
            <Route path="*" element={<Navigate to="tests" />} />
        </Routes>
    );
}

export default Hiring;
