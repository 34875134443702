import React, { createContext, useState, useContext } from "react";

const EngineContext = createContext();

export const useEngine = () => useContext(EngineContext);

export const EngineProvider = ({ children }) => {
    const [engines, setEngines] = useState({});

    const updateEngine = (contactId, engineType) => {
        setEngines(prev => ({ ...prev, [contactId]: engineType }));
    };

    return (
        <EngineContext.Provider value={{ engines, updateEngine }}>
            {children}
        </EngineContext.Provider>
    );
};
