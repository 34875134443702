import React, { useContext, useState } from "react";
import AuthContext from "../../../../../contexts/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../../../assets/constants/toast";
import CustomSearchInput from "../../../../custom/CustomSearchInput";
import constants from '../../../../../assets/constants/constants';
import { CircularProgress, IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const ConfigAdd = ({ setEnginesView, contact, refresh, setRefresh }) => {

    const [config, setConfig] = useState({
        zohoId: contact.id,
        stayTax: null,
        dedgeConfig: {},
        reservitConfig: {}
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'engines', config, { headers: { Authorization: 'Bearer ' + token } })
            .then(() => {
                setButtonLoading(false);
                toast.success("La configuration a bien été ajoutée", toastOptions);
                setRefresh(refresh + 1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    React.useEffect(() => console.log(config), [config]);

    return (
        <div>
            <div className='list-header'>
                <div className='view-title'>
                    <h1>Ajouter une configuration moteur</h1>
                </div>
                <div className='view-buttons'>
                    <button onClick={() => setEnginesView({ name: 'view' })} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button plus margin-left' onClick={handleCreate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress size={20} className="white-loader" /> : 'Ajouter'}</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className='input-label engine'>Type moteur <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un moteur'
                            entity={constants.engineTypes.map(engineType => { return { ...engineType, id: engineType.value } })}
                            labelType='constant'
                            onChange={(newValue) => { setConfig((config) => { return { ...config, engineType: newValue.value } }) }}
                        />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label id">Nom de l'hôtel <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={config.hotelName ? config.hotelName : ''} className='classic-input' placeholder='Entrez un nom' onChange={(e) => {
                            setConfig((config) => { return { ...config, hotelName: e.target.value } })
                        }} />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label id">Code moteur <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={config.engineCode ? config.engineCode : ''} className='classic-input' placeholder='Entrez un code moteur' onChange={(e) => {
                            setConfig((config) => { return { ...config, engineCode: e.target.value } })
                        }} />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label link">URL moteur <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={config.engineUrl ? config.engineUrl : ''} className='classic-input' placeholder='Entrez une URL' onChange={(e) => {
                            setConfig((config) => { return { ...config, engineUrl: e.target.value } })
                        }} />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label euro">Taxe de séjour</span>
                    <div className='input-container without-icon'>
                        <input type='number' min={0} step={0.01} value={config.stayTax ? config.stayTax : ''} className='classic-input' placeholder='Entrez une taxe de séjour' onChange={(e) => {
                            setConfig((config) => { return { ...config, stayTax: e.target.value } })
                        }} />
                        <Tooltip title="Si la taxe de séjour n'est pas incluse dans le prix total de la chambre, renseigner sa valeur par personne par nuit, sinon laisser vide." arrow placement='left'>
                            <div className='margin-left'>
                                <IconButton aria-label="delete" size="small">
                                    <HelpIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {config.engineType && (config.engineType.includes('dedge')) ? (
                    <>
                        <div className='input-row-container'>
                            <span className="input-label euro">Taxe de séjour (2)</span>
                            <div className='input-container without-icon'>
                                <input type='number' min={0} step={0.01} value={config.dedgeConfig.customStayTax ? config.dedgeConfig.customStayTax : ''} className='classic-input' placeholder='Entrez une taxe de séjour partielle' onChange={(e) => {
                                    setConfig((config) => { return { ...config, dedgeConfig: { ...config.dedgeConfig, customStayTax: e.target.value } } })
                                }} />
                                <Tooltip title="Si le prix comprend une partie de la taxe de séjour mais pas sa totalité, renseigner la partie prise en compte dans le prix dans ce champ." arrow placement='left'>
                                    <div className='margin-left'>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='input-row-container'>
                            <span className="input-label link">URL Booking</span>
                            <div className='input-container without-icon'>
                                <input value={config.dedgeConfig.bookingUrl ? config.dedgeConfig.bookingUrl : ''} className='classic-input' placeholder='Entrez une URL' onChange={(e) => {
                                    setConfig((config) => { return { ...config, dedgeConfig: { ...config.dedgeConfig, bookingUrl: e.target.value } } })
                                }} />
                            </div>
                        </div>
                        <div className='input-row-container'>
                            <span className="input-label link">URL Expedia</span>
                            <div className='input-container without-icon'>
                                <input value={config.dedgeConfig.expediaUrl ? config.dedgeConfig.expediaUrl : ''} className='classic-input' placeholder='Entrez une URL' onChange={(e) => {
                                    setConfig((config) => { return { ...config, dedgeConfig: { ...config.dedgeConfig, expediaUrl: e.target.value } } })
                                }} />
                            </div>
                        </div>
                        <div className='input-row-container'>
                            <span className="input-label token">Access Token</span>
                            <div className='input-container without-icon'>
                                <input value={config.dedgeConfig.accessToken ? config.dedgeConfig.accessToken : ''} className='classic-input' placeholder="Entrez un token d'accès" onChange={(e) => {
                                    setConfig((config) => { return { ...config, dedgeConfig: { ...config.dedgeConfig, accessToken: e.target.value } } })
                                }} />
                            </div>
                        </div>
                        <div className='input-row-container'>
                            <span className="input-label token">Secret Token</span>
                            <div className='input-container without-icon'>
                                <input value={config.dedgeConfig.secretToken ? config.dedgeConfig.secretToken : ''} className='classic-input' placeholder="Entrez un token d'accès" onChange={(e) => {
                                    setConfig((config) => { return { ...config, dedgeConfig: { ...config.dedgeConfig, secretToken: e.target.value } } })
                                }
                                } />
                            </div>
                        </div>
                        <div className='input-row-container'>
                            <span className='input-label id'>Groupe</span>
                            <div className='input-container'>
                                <CustomSearchInput placeholder='Rechercher un groupe'
                                    entity='engines/groups'
                                    labelType='engineGroup'
                                    onChange={newValue => setConfig(config => ({ ...config, dedgeConfig: { ...config.dedgeConfig, groupId: newValue.id } }))}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='input-row-container'>
                            <span className="input-label id">Préfixe moteur</span>
                            <div className='input-container without-icon'>
                                <input value={config.reservitConfig.enginePrefix ? config.reservitConfig.enginePrefix : ''} className='classic-input' placeholder="Entrez un préfixe" onChange={(e) => {
                                    setConfig((config) => { return { ...config, reservitConfig: { ...config.reservitConfig, enginePrefix: e.target.value } } })
                                }} />
                                <Tooltip title="Identifiant qui se trouve entre le /fo/booking et le code moteur dans l'URL du moteur" arrow placement='left'>
                                    <div className='margin-left'>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='input-row-container'>
                            <div className="input-label id">
                                <span>Identifiant prix</span>
                            </div>
                            <div className='input-container without-icon'>
                                <input value={config.reservitConfig.rateId ? config.reservitConfig.rateId : ''} className='classic-input' placeholder="Entrez un identifiant" onChange={(e) => {
                                    setConfig((config) => { return { ...config, reservitConfig: { ...config.reservitConfig, rateId: e.target.value } } })
                                }} />
                                <Tooltip title="Identifiant qui sera envoyé à Derbysoft par défaut sur l'API hotelRate (prendre un identifiant dans le BO Reservit de l'hôtel concerné)" arrow placement='left'>
                                    <div className='margin-left'>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ConfigAdd;
