import React from "react";
import CustomTag from "./CustomTag";
import dayjs from "dayjs";

const CustomOption = ({ innerProps, isDisabled, label, ...props }) => {

    return (
        !isDisabled ? (
            props.data.isInvoice ? (
                <div {...innerProps} className='custom-tag-container'>
                    <div className={ props.data.exactMatch ? 'invoice-option-select exact-match' : 'invoice-option-select' }>
                        <div className='invoice-option-select-infos'>
                            <div>
                                <span className='invoice-number'>{label}</span>
                                <small className='invoice-date'>{dayjs(props.data.data.date).format('DD/MM/YYYY')}</small>
                            </div>
                            <div>
                                <small>Total : <span className='invoice-total'>{Number(props.data.data.total).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span></small>
                                <small>Balance : <span className='invoice-balance'>{Number(props.data.data.balance).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span></small>
                            </div>
                        </div>
                        <span className='invoice-customer'>{props.data.data.customer_name ? props.data.data.customer_name : props.data.data.vendor_name}</span>
                    </div>
                </div>
            ) : (
                props.data.id === 'failed' ? (
                    <div {...innerProps} className='custom-tag-container'>
                        <div className='invoice-option-select failed'>
                            <div className='invoice-option-select-infos'>
                                <span className='invoice-number'>{label}</span>
                            </div>
                            <span className='invoice-customer'>Une nouvelle soumission a été demandée, ce paiement sera marqué comme un débit jusqu'à réception du nouveau paiement.</span>
                        </div>
                    </div>
                ) : (
                    <div {...innerProps} className='custom-tag-container'>
                        <CustomTag value={label} className={props.data.value} />
                    </div>
                )
            )
        ) : null
    );
}

export default CustomOption;