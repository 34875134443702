import React, { useContext, useState, useEffect } from "react";
import CustomTag from "../../../custom/CustomTag";
import CustomList from "../../../custom/CustomList";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import AuthContext from "../../../../contexts/AuthContext";
import { Tab, Tabs } from "@mui/material";
const ContactSupport = ({ contact, setContact, refresh, setRefresh }) => {

    const { token } = useContext(AuthContext);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        const years = Object.keys(contact.tickets).reverse();
        const index = years.indexOf(String(dayjs().year()));
        if (index !== -1) {
            setTab(index);
        }
    }, [contact.tickets]);

    const backgroundCustomTagConsommees = contact.supportHours <= contact.paidSupportHours ? "green-background" : "red-background";

    const handlePaidHours = () => {
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'books/contacts/' + contact.id, { paidSupportHours: contact.paidSupportHours }, { headers: { Authorization: 'Bearer ' + token } })
            .then(() => {
                setRefresh(refresh + 1);
                toast.success('Heures modifiées', toastOptions);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const ticketsCols = React.useMemo(
        () => [
            {
                Header: 'Objet',
                accessor: 'data',
                downloadAccessor: 'title',
                icon: 'objet',
                Cell: ({ cell: { value } }) => <a href={value.webUrl} rel="noreferrer" target="_blank" >{value.subject}</a>
            },
            {
                Header: 'Temps (en h.)',
                icon: 'conso',
                accessor: 'time',
                Cell: ({ cell: { value } }) => <span>{value.toFixed(1)} h</span>
            },
            {
                Header: 'Date',
                accessor: 'data.closedTime',
                icon: 'calendar',
                Cell: ({ cell: { value } }) => <span>{dayjs(value).format('DD/MM/YYYY')}</span>
            },
            {
                Header: 'URL Desk',
                accessor: 'data.webUrl',
                id: 'desk_url'
            }
        ],
        []
    );

    return (
        <div className='tab-container'>
            <div className='input-row-container'>
                <span className="input-label support">Type de support :</span>
                <CustomTag value={contact.supportType} />
            </div>
            <div className='input-row-container'>
                <span className="input-label clock">Heures payées :</span>
                <input type='number' min={0} className='classic-input number-input margin-right' value={contact.paidSupportHours} onChange={(e) => setContact({ ...contact, paidSupportHours: e.target.value })} />
                <button className='edit-button' onClick={handlePaidHours}>Modifier</button>
            </div>
            {Object.keys(contact.tickets).length > 0 ? (
                <div className='tabs-container'>
                    <div className="tabs-wrapper">
                        <Tabs value={tab} onChange={(event, value) => setTab(value)} >
                            {Object.keys(contact.tickets).reverse().map((year, index) => (
                                <Tab value={index} key={index} label={year + ' (' + contact.tickets[year].length + ')'} />
                            ))}
                        </Tabs>
                        {tab === 0 && (
                            <div className="tabs-consommees-wrapper">
                                <span className="tabs-consommees-texte">Total des heures consommées :</span>
                                <CustomTag className={backgroundCustomTagConsommees} value={contact.supportHours} />
                                <span>/ {contact.paidSupportHours}h</span>
                            </div>
                        )}
                    </div>
                    {Object.values(contact.tickets).reverse().map((yearTickets, index) => (
                        <div key={index} role="tabpanel" hidden={tab !== index}>
                            <CustomList entity={yearTickets} columns={ticketsCols} hiddenColumns={['desk_url']} isSelectable={false} isFetchable={false} isClickable={false} isDownloadable limit={20} />
                        </div>
                    ))}
                </div>
            ) : (
                <p className='waiting-order-warning'>Ce client ne possède aucun ticket pour le moment.</p>
            )}

        </div>
    );
}

export default ContactSupport;