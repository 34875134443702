import {useParams} from "react-router-dom";
import React, {useState} from "react";
import altelis_logo from "../../assets/images/altelis-full.svg";
import not_found from "../../assets/images/order-404.svg";
import valid from "../../assets/images/valid-subscription.png"
import {CircularProgress} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import toastOptions from "../../assets/constants/toast";
import CountdownTimer from "./CountDown";

const TestSubmit = () => {

    const { testId, userId } = useParams();
    const [refresh, setRefresh] = useState(0);
    const [file, setFile] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [test, setTest] = useState();
    const [user, setUser] = useState();

    React.useEffect(() => console.log(file), [file]);

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'tests/' + testId + '/' + userId)
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setTest(response.data.test);
                    setUser(response.data);
                } else {
                    setError(true);
                }
                setIsLoaded(true);
            })
            .catch(() => {
                setError(true);
                setIsLoaded(true);
            });
    }, [refresh, testId, userId]);

    const handleRemaining = (remaining) => {
        if(remaining < 0) {
            setRefresh(refresh + 1);
        }
    }

    const submitFile = () => {
        if(file) {
            const formData = new FormData();
            formData.append('file', file);

            axios.post(process.env.REACT_APP_UPA_API_HOST + 'tests/' + testId + '/' + userId, formData)
                .then(() => {
                    toast.success("Votre fichier a bien été déposé", toastOptions);
                    setFile(null);
                    setRefresh(refresh + 1);
                })
                .catch(error => {
                    toast.error(error.response.data.message, toastOptions);
                });
        }
    }

    return isLoaded ? (
        !error ? (
            <div className='order-portal'>
                <div className='portal-header'>
                    <div className='portal-title'>
                        <div className='logo-container'>
                            <img src={altelis_logo} alt='portal-logo' width={100} />
                        </div>
                        <div>
                            <h1>{test.name}</h1>
                            <span>#{test.id}</span>
                        </div>
                    </div>
                    <a href='tel:01 85 09 74 06' className='portal-phone'>
                        <span>01 85 09 74 06</span>
                    </a>
                </div>
                { dayjs() > dayjs(test.startDate) && dayjs() < dayjs(test.stopDate) ? (
                    <div className='test-content'>
                        <h1>Bonjour, {user.firstName} {user.lastName.toUpperCase()}</h1>
                        { dayjs() < dayjs(user.startDate) ? (
                            <div>
                                <div className='test-duration'>
                                    <span>Votre test est prévu le <strong>{dayjs(user.startDate).format('dddd DD MMMM à HH:mm')}</strong> pour une durée de { test.hours > 0 ? <strong>{test.hours} heure{test.hours > 1 ? 's' : ''} </strong> : null }{ test.minutes > 0 ? <strong>{test.minutes} minute{test.minutes > 1 ? 's' : ''}</strong> : null }.</span>
                                </div>
                                <div className='test-duration'>
                                    <span>Le sujet sera disponible sur cette page dès le début de la période du test.</span>
                                    <CountdownTimer targetDate={dayjs(user.startDate).valueOf()} introText="Votre test commence dans : " setRemaining={handleRemaining}/>
                                </div>
                            </div>
                        ) : (dayjs() < dayjs(user.stopDate) ? (
                            <div className='test-duration'>
                                <span>Bon courage !</span>
                                <CountdownTimer targetDate={dayjs(user.stopDate).valueOf()} displayDays={false} introText="Votre test se termine dans : " setRemaining={handleRemaining} />
                                <a href={test.subjectLink} target="_blank" rel="noreferrer">
                                    <button className='main-button blue-button launch'>Lien du sujet</button>
                                </a>
                                <div className='test-duration'>
                                    <span><strong>Rappel : </strong> Seuls les fichiers compressés au format <strong>.zip</strong> sont acceptés.</span>
                                </div>
                                <div className='file-container'>
                                    <label htmlFor='file' className={ file ? 'metas-file-chooser data' : 'metas-file-chooser' }>
                                        <span>{ !file ? 'Déposez ici votre fichier' : file.name}</span>
                                        <input accept=".zip" type="file" name="file" id='file' onChange={event => setFile(event.target.files[0])} />
                                    </label>
                                    <button className='main-button blue-button send' onClick={submitFile} disabled={!file}>Déposer</button>
                                </div>
                                { user.submittedAt ? (
                                    <div className='test-duration'>
                                        <span>Votre fichier a été déposé à <strong>{dayjs(user.submittedAt).format('HH:mm:ss')}</strong></span>
                                    </div>
                                ) : null }
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <img src={valid} className='test-done' alt='not found' width={120}/>
                                </div>
                                <div className='test-duration'>
                                    <span>Votre test est <strong>terminé</strong>. Merci pour votre implication !</span>
                                </div>
                                <div className='test-duration'>
                                    <span>Nous reviendrons vers vous au plus vite.</span>
                                </div>
                                <div className='test-duration'>
                                    <span>L'équipe Altelis</span>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='portal-content flex-view'>
                        <div className='order-404'>
                            <img src={not_found} alt='not found' width={400}/>
                            <h1>Oups...</h1>
                            <p>Ce test n'est disponible que du <strong>{dayjs(test.startDate).format('DD MMMM YYYY')}</strong> au <strong>{dayjs(test.stopDate).format('DD MMMM YYYY')}</strong></p>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className='portal-content flex-view'>
                <div className='order-404'>
                    <img src={not_found} alt='not found' width={400}/>
                    <h1>Oups...</h1>
                    <p>Le test que vous cherchez n'est pas disponible pour le moment. Merci de réessayer plus tard.</p>
                </div>
            </div>
        )
    ) : (
        <div className='loader-container'><CircularProgress size={30} /></div>
    );

}

export default TestSubmit;