import React, { useContext, useState } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import { CircularProgress } from "@mui/material";

const TestEdit = () => {

    const [test, setTest] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const { testId } = useParams();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'tests/' + testId, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => {
                if (response.data) {
                    console.log(response.data);
                    setTest(response.data);
                    setLoading(false);
                } else {
                    toast.error('Test inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [navigate, token, testId]);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'tests/' + testId, test, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le test a bien été modifié", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    return !loading ? (
        <div>
            <div className='list-header'>
                <div className='header-input'>
                    <input type='text' placeholder='Nom du test' value={test.name ? test.name : ''} onChange={(e) => { setTest({ ...test, name: e.target.value }) }} />
                </div>
                <div className='header-buttons'>
                    <button onClick={() => navigate(-1)} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button validate margin-left' onClick={handleUpdate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress size={20} className="white-loader" /> : 'Modifier'}</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className='input-label calendar'>Dates de validité <span className='required'>*</span></span>
                    <span className='margin-right'>Du</span>
                    <input type="date" className="classic-input" value={test.startDate} onChange={(e) => setTest({ ...test, startDate: e.target.value })} />
                    <span className='margin-right margin-left'>au</span>
                    <input type="date" className="classic-input" value={test.stopDate} onChange={(e) => setTest({ ...test, stopDate: e.target.value })} />
                </div>
                <div className='input-row-container'>
                    <span className='input-label calendar'>Durée du test <span className='required'>*</span></span>
                    <input type="number" className="classic-input" min={0} max={10} step={1} value={test.hours} onChange={(e) => setTest({ ...test, hours: parseInt(e.target.value) })} placeholder="Heures" />
                    <span className='margin-left margin-right'>heure(s) et</span>
                    <input type="number" className="classic-input margin-right" min={0} max={60} step={15} value={test.minutes} onChange={(e) => setTest({ ...test, minutes: parseInt(e.target.value) })} placeholder="Minutes" />
                    <span>minute(s)</span>
                </div>
                <div className='input-row-container'>
                    <span className='input-label link'>Lien du sujet <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input type="text" className="classic-input" placeholder="Saisissez un lien Notion" value={test.subjectLink ? test.subjectLink : ''} onChange={(e) => { setTest({ ...test, subjectLink: e.target.value }) }} />
                    </div>
                </div>
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={20} className="white-loader" /></div>
}

export default TestEdit;
