import React, {useState} from "react";
import { TextField, Button, CircularProgress, Grid, Chip } from '@mui/material';
import { useParams } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas';
import axios from "axios";

const FormGenerator = () => {

    const [pageLoaded, setPageLoaded] = useState(false);
    const [signatureError, setSignatureError] = useState(false);
    const [signed, setSigned] = useState(false);
    const [proposalStatus, setProposalStatus] = useState(null);
    const [data, setData] = useState({ firstName: '', lastName: '', role: '', company: '', email: '', iban: '', bic: '', accountOwner: '', signature: '', siren: '', tva: '', address: '', city: '', postalCode: '' });
    const { pageId } = useParams();
    let sigPad = {};

    React.useEffect(() => {
        axios.get( process.env.REACT_APP_PRODUCTION_URL + '/api/sign/' + pageId)
            .then((response) => {
                setProposalStatus(response.data.status);
                setPageLoaded(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [pageId])

    const resetPad = () => {
        sigPad.clear()
        setSignatureError(false)
    }

    const handleSign = () => {
        console.log(sigPad);
        let canValidate = true;
        for (const [key, value] of Object.entries(data)) {
            if(key !== 'signature' && key !== 'accountOwner' && value.length === 0) {
                canValidate = false;
            }
        }
        if(validateSignature() && canValidate) {
            setPageLoaded(false);
            axios.post( process.env.REACT_APP_PRODUCTION_URL + '/api/sign/' + pageId,{
                ...data,
                signature : sigPad.toDataURL()
            })
                .then((response) => {
                    console.log(response);
                    setData({...data, signedOn: response.data.signedOn });
                    setSigned(true);
                    setPageLoaded(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const validateSignature = () => {
        let signatureSize = 0;
        console.log(sigPad);
        const signaturePoints = sigPad.toData();
        if(signaturePoints.length > 0) {
            signaturePoints.forEach(array => signatureSize += array.length);
        }
        signatureSize < 40 ? setSignatureError(true) : setSignatureError(false);
        return signatureSize >= 40;
    }

    return pageLoaded ? (
        proposalStatus && proposalStatus !== 'Signée' ? (
            !signed ? (
                <div className="App">
                    <div className='form-container'>
                        <h3>Merci de remplir les informations suivantes</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField error={data.siren.length === 0} size="small" id="outlined-basic" label="N° SIREN" variant="outlined" fullWidth value={data.siren} onChange={e => setData({...data, siren: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.tva.length === 0} size="small" id="outlined-basic" label="N° TVA" variant="outlined" fullWidth value={data.tva} onChange={e => setData({...data, tva: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.company.length === 0} size="small" id="outlined-basic" label="Nom de l'entreprise" variant="outlined" fullWidth value={data.company} onChange={e => setData({...data, company: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.address.length === 0} size="small" id="outlined-basic" label="Adresse" variant="outlined" fullWidth value={data.address} onChange={e => setData({...data, address: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.postalCode.length === 0} size="small" id="outlined-basic" label="Code postal" variant="outlined" fullWidth value={data.postalCode} onChange={e => setData({...data, postalCode: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.city.length === 0} size="small" id="outlined-basic" label="Ville" variant="outlined" fullWidth value={data.city} onChange={e => setData({...data, city: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.firstName.length === 0} size="small" id="outlined-basic" label="Prénom" variant="outlined" fullWidth value={data.firstName} onChange={e => setData({...data, firstName: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.lastName.length === 0} size="small" id="outlined-basic" label="Nom" variant="outlined" fullWidth value={data.lastName} onChange={e => setData({...data, lastName: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.role.length === 0} size="small" id="outlined-basic" label="Fonction" variant="outlined" fullWidth value={data.role} onChange={e => setData({...data, role: e.target.value })} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField error={data.email.length === 0} size="small" id="outlined-basic" label="Email" variant="outlined" fullWidth value={data.email} onChange={e => setData({...data, email: e.target.value })} />
                            </Grid>
                            <Grid item xs={12}>
                                <p>Merci de remplir votre RIB pour le prélèvement Sepa : </p>
                                <small><em>En signant ce document, vous autorisez le partenaire de paiement Go Cardless d'Altelis à envoyer des instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément aux instructions d'Altelis. Vous bénéficiez d’un droit à remboursement par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte.</em></small>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField error={data.iban.length === 0} size="small" id="outlined-basic" label="IBAN (ex : FR7612548029989876543210)" variant="outlined" fullWidth value={data.iban} onChange={e => setData({...data, iban: e.target.value })} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField error={data.bic.length === 0} size="small" id="outlined-basic" label="BIC (ex : CEPAFRPP751)" variant="outlined" fullWidth value={data.bic} onChange={e => setData({...data, bic: e.target.value })} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="outlined-basic" label="Nom du titulaire si différent" variant="outlined" size="small" fullWidth value={data.accountOwner} onChange={e => setData({...data, accountOwner: e.target.value })} />
                            </Grid>
                            <Grid item xs={12}>
                                <p>Signature : </p>
                                <div className='signature-container'>
                                    { signatureError ? (
                                        <div className='error-signature'>
                                            <Chip label="La signature est trop petite" color="warning" />
                                        </div>
                                    ) : null }
                                    <div className="erase-signature">
                                        <Button variant="contained" size="small" onClick={resetPad}>Effacer</Button>
                                    </div>
                                    <SignatureCanvas canvasProps={{className: 'sigCanvas'}} ref={(ref) => { sigPad = ref }} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <button className="main-button blue-button validate" onClick={handleSign} >Signer la proposition</button>
                            </Grid>
                        </Grid>
                        <br />
                        <h3>En vous remerciant pour cette collaboration et votre confiance</h3>
                    </div>
                </div>
            ) : (
                <div className='signed-container'>
                    <h4>Informations personnelles</h4>
                    <p>N° SIREN : {data.siren}</p>
                    <p>N° TVA : {data.tva}</p>
                    <p>Entreprise : {data.company}</p>
                    <p>Adresse : {data.address}</p>
                    <p>Code postal : {data.postalCode}</p>
                    <p>Ville : {data.city}</p>
                    <p>Prénom : {data.firstName}</p>
                    <p>Nom : {data.lastName}</p>
                    <p>Fonction : {data.role}</p>
                    <p>Email : {data.email}</p>
                    <h4>Informations bancaires</h4>
                    <p>IBAN : {data.iban}</p>
                    <p>BIC : {data.bic}</p>
                    <p>Nom du titulaire si différent : {data.accountOwner ? data.accountOwner : 'Non renseigné'}</p>
                    <p>A Courbevoie,</p>
                    <p>Le : {data.signedOn}</p>
                    <img src={process.env.REACT_APP_PRODUCTION_URL + "/api/signatures/" + pageId + ".png"} alt="signature"/>
                    <h3>Merci pour votre commande !</h3>
                </div>
            )
        ) : (
            <div>
                <h1>Proposition déjà signée</h1>
            </div>
        )
    ) : (
        <div className='loader-container'>
            <CircularProgress />
        </div>
    )
}

export default FormGenerator;