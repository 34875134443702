import React, { useContext, useState } from "react";
import { Box, Modal } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import AuthContext from "../../../../contexts/AuthContext";
import axios from "axios";
import tick from "../../../../assets/icons/check--green.svg";
import fail from "../../../../assets/icons/exclamation--red.svg";

const BillCreator = ({ transactions = [], setTransactions, refreshData, setRefreshData }) => {

    const [importerOpen, setImporterOpen] = useState(false);
    const [occurrence, setOccurrence] = useState(-1);
    const handleOpenImporter = () => setImporterOpen(true);
    const handleCloseImporter = () => setImporterOpen(false);
    const { token } = useContext(AuthContext);

    const handleImport = async () => {
        handleOpenImporter();
        setOccurrence(occurrence + 1);
    }

    const handleQuit = () => {
        handleCloseImporter();
        setRefreshData(refreshData + 1);
        setOccurrence(-1);
    }

    React.useEffect(() => {
        if (transactions.length > 0) {
            if (occurrence >= 0 && occurrence < transactions.length) {
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'metas/create-invoice', transactions[occurrence], { headers: { Authorization: 'Bearer ' + token } })
                    .then(() => {
                        setTransactions(transactions.map((row) => {
                            return row.engineCode === transactions[occurrence].engineCode ? { ...row, result: 'success' } : row
                        }));
                        setOccurrence(occurrence + 1);
                    })
                    .catch(err => {
                        console.log(err.response);
                        setTransactions(transactions.map((row) => {
                            return row.engineCode === transactions[occurrence].engineCode ? { ...row, result: 'error' } : row
                        }));
                        setOccurrence(occurrence + 1);
                    });
            }
        }
    }, [occurrence, token, setTransactions, transactions]);

    return (
        <div>
            <button className="main-button blue-button launch" onClick={handleImport} disabled={transactions.length === 0}>Créer {transactions.reduce((total, trans) => trans.billingCost ? total + 1 : total, 0)} factures</button>
            {transactions && (
                <Modal open={importerOpen} aria-labelledby="transactions-importer" aria-describedby="transactions-importer" onClose={(_, reason) => { if (reason !== "backdropClick") { handleCloseImporter() } }}>
                    <Box className="main-modal" id='transactions-importer'>
                        <h5>Import de vos transactions</h5>
                        {transactions.map((transaction, index) => (
                            <div className={'importer-info ' + (transaction.result ? (transaction.result === 'success' ? 'success' : 'fail') : '')} key={index}>
                                {transaction.billingCost && transaction.billingCost > 0 ? (
                                    <span>Création de la facture de <strong>{transaction.billingCost} €</strong> pour <strong>{transaction.contact.data.customer_name}</strong></span>
                                ) : (
                                    <span>Pas de facture pour <strong>{transaction.contact.data.customer_name}</strong></span>
                                )}
                                {transaction.result ? (
                                    <div>
                                        {transaction.result === 'success' ? (
                                            <img src={tick} alt="tick" width={20} />
                                        ) : (
                                            <img src={fail} alt="tick" width={20} />
                                        )}
                                    </div>
                                ) : (
                                    <CircularProgress className='margin-left' size={20} />
                                )}
                            </div>
                        ))}
                        {(occurrence === transactions.length) && (
                            <div className="flex-right">
                                <button onClick={handleQuit} className="main-button blue-button launch">Terminer</button>
                            </div>
                        )}
                    </Box>
                </Modal>
            )}
        </div>
    )
}

export default BillCreator;