import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import CustomList from '../../../custom/CustomList';
import AuthContext from '../../../../contexts/AuthContext';
import { CircularProgress } from "@mui/material";
import CustomTag from "../../../custom/CustomTag";

const ContactMacro = () => {
    const [contacts, setContacts] = useState([]);
    const [totalMois, setTotalMois] = useState(0);
    const [totalTrimestre, setTotalTrimestre] = useState(0);
    const [totalAnnee, setTotalAnnee] = useState(0);
    const mrrTotal = totalMois + (totalTrimestre / 3) + (totalAnnee / 12);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios.get(`${process.env.REACT_APP_UPA_API_HOST}books/contacts`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                const rawData = response.data;
                let sommeMois = 0;
                let sommeTrimestre = 0;
                let sommeAnnee = 0;
                const dataContact = rawData.reduce((acc, contact) => {
                    const facturationsRecurrentes = contact.recurringLines && contact.recurringLines.length > 0;
                    if (!facturationsRecurrentes) return acc;

                    let totalMois = 0;
                    let totalTrimestre = 0;
                    let totalAnnee = 0;

                    contact.recurringLines.forEach(line => {
                        const montantFacturation = parseFloat(line.item_total);
                        if (line.recurrence_frequency === 'months' && line.repeat_every === 1) {
                            totalMois += montantFacturation;
                            sommeMois += montantFacturation;
                        } else if (line.recurrence_frequency === 'months' && line.repeat_every === 3) {
                            totalTrimestre += montantFacturation;
                            sommeTrimestre += montantFacturation;
                        } else if (line.recurrence_frequency === 'years') {
                            totalAnnee += montantFacturation;
                            sommeAnnee += montantFacturation;
                        }
                    });

                    acc.push({
                        contact_name: contact.data.contact_name,
                        mois: totalMois,
                        trimestre: totalTrimestre,
                        annee: totalAnnee,
                        idAltelis: contact.data.custom_fields?.find(f => f.api_name === "cf_n_du_compte_plan_comptable")?.value.slice(-3) || 'Non renseigné',
                    });

                    return acc;
                }, []);

                setContacts(dataContact);
                setTotalMois(sommeMois);
                setTotalTrimestre(sommeTrimestre);
                setTotalAnnee(sommeAnnee);
                setIsLoaded(true);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des contacts :', error);
                setIsLoaded(true);
            });
    }, [token]);

    const affichageCustomTag = (value) => {
        const isNumber = typeof value === 'number' && !isNaN(value);
        return <CustomTag value={isNumber ? `${value.toFixed(2)}€` : 'Non renseigné'} className={isNumber && value > 0 ? "active" : "deactivated"} />;
    };

    const columns = React.useMemo(() => [
        {
            Header: "Id Altelis",
            accessor: "idAltelis",
            icon: "user",
            Cell: ({ value }) => <CustomTag value={value} className={value !== 'Non renseigné' ? '' : 'deactivated'} />
        },
        {
            Header: "Nom de l'hôtel",
            accessor: "contact_name",
            icon: "user",
            Cell: ({ value }) => value.toUpperCase(),
        },
        {
            Header: "Tous les mois",
            accessor: "mois",
            icon: "calendar",
            Cell: ({ value }) => affichageCustomTag(value),
        },
        {
            Header: "Tous les 3 mois",
            accessor: "trimestre",
            icon: "calendar",
            Cell: ({ value }) => affichageCustomTag(value),
        },
        {
            Header: "Tous les ans",
            accessor: "annee",
            icon: "calendar",
            Cell: ({ value }) => affichageCustomTag(value),
        },
        {
            Header: "MRR",
            accessor: (row) => row.mois + (row.trimestre / 3) + (row.annee / 12),
            id: "mrr",
            icon: "euro",
            Cell: ({ value }) => affichageCustomTag(value),
        }
    ], []);

    return (
        <div>
            {isLoaded ? (
                <>
                    <div className="macro-total-wrapper">
                        <div className="macro-total-content">
                            <span><strong>Mois</strong>&nbsp;: {totalMois.toFixed(2)}€</span>
                            <span><strong>Trimestre</strong>&nbsp;: {totalTrimestre.toFixed(2)}€</span>
                            <span><strong>Année</strong>&nbsp;: {totalAnnee.toFixed(2)}€</span>
                        </div>
                        <span><strong>MRR</strong>&nbsp;: {mrrTotal.toFixed(2)}€</span>
                    </div>
                    <div className="view-container">
                        <div className="tabs-container">
                            <CustomList
                                columns={columns}
                                entity={contacts}
                                isSelectable={false}
                                isFetchable={false}
                                isClickable={false}
                                isDownloadable={false}
                                isFixed={false}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className='loader-container'><CircularProgress size={30} /></div>
            )}
        </div>
    );
};

export default ContactMacro;