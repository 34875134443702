import favicon from "../../../assets/images/project-favicon.png";
import CustomTag from "../../custom/CustomTag";
import {Link} from "react-router-dom";
import React from "react";
import ProjectCategories from "./ProjectCategories";

const ProjectCard = ({project}) => {

    return (
        <Link to={'/admin/projects/' + project.id}>
            <div className="project-card">
                <div className="project-card-header">
                    <div className="project-card-title">
                        { project.iconEmoji ? (
                            <span>{project.iconEmoji}</span>
                        ) : (( project.iconFile ? (
                            <img src={project.iconFile} alt='project-icon' width={20} height={20} />
                        ) : (
                            <img src={favicon} alt='project-icon' width={20} />
                        )))}
                        <h1>{ project.name ? project.name : "Non renseigné" }</h1>
                    </div>
                    <div className="flex-center">
                        <CustomTag value={project.workingDaysElapsed + ' j.'} className="margin-right" />
                        <CustomTag value={project.status ? project.status : "Non renseigné"} className={project.statusColor ? project.statusColor : "debit"} />
                    </div>
                </div>
                <ProjectCategories project={project} />
            </div>
        </Link>
    )
}

export default ProjectCard;