import React, { useEffect, useState } from "react";
import CustomTag from "../../../custom/CustomTag";
import CustomList from "../../../custom/CustomList";
import CustomInventaireFilter from '../../../custom/CustomInventaireFilter';
import { Tab, Tabs, Button } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import GroupList from "../groups/GroupList";

import IconeFiltre from "../../../../assets/icons/filter-black.svg";
import ContactMacro from "../clients/ContactMacro";

const InventaireTout = (url) => {

    const [tab, setTab] = useState(0);
    const [tabLabel, setTabLabel] = useState("Clients");
    const [iconeTab, setIconeTab] = useState("clients");
    const location = useLocation();
    const navigate = useNavigate();

    const urlTabChangement = (event, newValue) => {
        let path = "/admin/inventaire/";
        switch (newValue) {
            case 0:
                path += "clients";
                setIconeTab("clients");
                break;
            case 1:
                path += "fournisseurs";
                setIconeTab("fournisseurs");
                break;
            case 2:
                path += "groups";
                setIconeTab("groupes");
                break;
            case 3:
                path += "macro";
                setIconeTab("macro");
                break;
            default:
                path += "clients";
                setIconeTab("clients");
        }
        navigate(path);
        setTab(newValue);
    };

    useEffect(() => {
        let initialTab = 0;
        if (location.pathname.includes('/fournisseurs')) {
            initialTab = 1;
            setIconeTab("fournisseurs");
        } else if (location.pathname.includes('/groups')) {
            initialTab = 2;
            setIconeTab("groupes");
        } else if (location.pathname.includes('/macro')) {
            initialTab = 3;
            setIconeTab("macro");
        }
        setTab(initialTab);
    }, [location]);

    useEffect(() => {
        setTabLabel(changementTitreTab(tab));
    }, [tab]);

    const changementTitreTab = (tabIndex) => {
        switch (tabIndex) {
            case 0:
                return "Clients";
            case 1:
                return "Fournisseurs";
            case 2:
                return "Groupes";
            case 3:
                return "Macro";
            default:
                return "Inventaire";
        }
    };

    const [ouvert, setOuvert] = useState(false);
    const [filtres, setFiltres] = useState({
        cms: [],
        builder: [],
        support: [],
        moteur: [],
        webServices: [],
        traduction: [],
    });

    const reinitialiserTousLesFiltres = () => {
        setFiltres({
            cms: [],
            builder: [],
            support: [],
            moteur: [],
            webServices: [],
            traduction: [],
        });
    };

    const ouvrirModal = () => {
        setOuvert(true);
    };

    const fermerModal = () => {
        setOuvert(false);
    };

    const changerFiltre = (categorie, valeur) => {
        setFiltres(prev => ({
            ...prev,
            [categorie]: prev[categorie].includes(valeur)
                ? prev[categorie].filter(v => v !== valeur)
                : [...prev[categorie], valeur]
        }));
    };

    const reinitialiserGroupe = (groupe) => {
        setFiltres({ ...filtres, [groupe]: [] });
    };

    const customerCols = React.useMemo(
        () => [
            {
                Header: 'Id Altelis',
                accessor: 'data.custom_fields',
                icon: 'role',
                Cell: ({ row }) => {
                    const field = row.original.data.custom_fields.find(f => f.api_name === "cf_n_du_compte_plan_comptable");
                    const rawIdAltelis = field ? field.value : 'Non renseigné';

                    const idAltelis = rawIdAltelis !== 'Non renseigné' ? rawIdAltelis.slice(-3) : rawIdAltelis;

                    return (<CustomTag value={idAltelis} className={idAltelis !== 'Non renseigné' ? '' : 'deactivated'} />);
                }
            },
            {
                Header: 'Nom',
                accessor: 'data.contact_name',
                icon: 'user',
                Cell: row => (
                    <div className='contact-list-customer-name'>
                        <div className='contact-list-img'>
                            <img src={row.row.original.logoUrl ? row.row.original.logoUrl : 'https://image.store.altelis.com/images/altelis-tools/logo-altelis.png'} alt='contact-logo' />
                        </div>
                        <span>{row.row.original.data.contact_name}</span>
                    </div>
                )
            },
            {
                Header: 'Statut',
                accessor: 'data.status',
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value ? value : 'Non renseigné'} className={value ? '' : 'deactivated'} />)
            },
            {
                Header: 'Support',
                accessor: 'supportType',
                icon: 'support',
                Cell: ({ cell: { value } }) => (<CustomTag value={value ? value : 'Non renseigné'} className={value ? '' : 'deactivated'} />)
            },
            {
                Header: 'Conso.', accessor: 'supportHours', icon: 'conso', className: 'col-conso', Cell: ({ cell: { value }, row }) => {
                    const paidSupportHours = row.original.paidSupportHours || 0;
                    const exceedsLimit = value > paidSupportHours;
                    return (
                        <div className="conso-wrapper">
                            <CustomTag value={`${value ? value : 0}`} className={exceedsLimit ? 'red-background' : 'green-background'} />
                            {` / ${paidSupportHours} h`}
                        </div>
                    );
                }
            },
            {
                Header: 'Lien du site',
                accessor: 'websiteUrl',
                icon: 'link',
                Cell: ({ cell: { value } }) => (<CustomTag value={value ? value : 'Non renseigné'} className={value ? '' : 'deactivated'} />)
            },
            {
                Header: 'CMS',
                accessor: '',
                icon: 'cms',
                Cell: ({ cell: { value } }) => (<CustomTag value={value ? value : 'Non renseigné'} className={value ? '' : 'deactivated'} />)
            },
            {
                Header: 'Groupe',
                accessor: 'group.name',
                icon: 'link',
                Cell: ({ row }) => {
                    const groupName = row.original.group ? row.original.group.name : 'Aucun';
                    return (<CustomTag value={groupName} className={groupName !== 'Aucun' ? '' : 'deactivated'} />);
                }
            },
            {
                Header: 'Moteur',
                accessor: row => row.engineType || 'Aucun',
                icon: 'calendar',
                Cell: ({ value }) => <CustomTag value={value} className={value !== 'Aucun' ? '' : 'deactivated'} />,
            },
            {
                Header: 'Id ZohoBooks',
                accessor: 'id',
                icon: 'role',
                className: 'col-id sticky-col sticky-col-first',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            }
        ], []
    );

    const vendorCols = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'data.contact_name',
                icon: 'user'
            },
            {
                Header: 'Statut',
                accessor: 'data.status',
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            }
        ], []
    );

    const groupCols = React.useMemo(
        () => [
            {
                Header: 'Nom',
                accessor: 'name',
                icon: 'user'
            },
            {
                Header: 'Support mutualisé',
                accessor: 'shared',
                icon: 'role',
                Cell: ({ value }) => <CustomTag className={value ? 'true' : 'false'} value={value ? 'Oui' : 'Non'} />
            }
        ],
        []
    );

    return (
        <div className="view-container">
            <div className="view-header">
                <div className={`header-title ${iconeTab}`}>
                    <h1>{tabLabel}</h1>
                </div>
                <div className="tab-header-inventaire">
                    {tab === 2 && (
                        <Link className="tab-inventaire-link" to="new">
                            <button className='main-button blue-button plus'>Ajouter un groupe</button>
                        </Link>
                    )}
                    {tab === 0 && (
                        <>
                            <Button onClick={ouvrirModal} aria-label="Filtrer par statut">
                                <img src={IconeFiltre} alt="Icône Filtre" />
                                Filtres
                            </Button>
                            <CustomInventaireFilter
                                ouvert={ouvert}
                                fermerModal={fermerModal}
                                filtres={filtres}
                                changerFiltre={changerFiltre}
                                reinitialiserGroupe={reinitialiserGroupe}
                                reinitialiserTousLesFiltres={reinitialiserTousLesFiltres}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="tabs-container">
                <Tabs value={tab} onChange={urlTabChangement} >
                    <Tab value={0} label={"Clients"} />
                    <Tab value={1} label={"Fournisseurs"} />
                    <Tab value={2} label={"Groupes"} />
                    <Tab value={3} label={"Macro"} />
                </Tabs>
                <div role="tabpanel" id={0} hidden={tab !== 0} >
                    <CustomList entity="books/customers" columns={customerCols} isSelectable={false} isScrollable={true} limit={100} entityType="clients" />
                </div>
                <div role="tabpanel" id={1} hidden={tab !== 1} >
                    <CustomList entity='books/vendors' columns={vendorCols} isSelectable={false} isClickable={false} entityType="fournisseurs" />
                </div>
                <div role="tabpanel" id={2} hidden={tab !== 2} >
                    <CustomList entity='books/groups' columns={groupCols} entityType="groups" />
                </div>
                <div role="tabpanel" id={3} hidden={tab !== 3} >
                    <ContactMacro />
                </div>
            </div>
        </div>
    )
}

export default InventaireTout;