import React, {useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import { toast } from "react-toastify";
import CustomTag from "../../../custom/CustomTag";
import toastOptions from "../../../../assets/constants/toast";
import {CircularProgress} from "@mui/material";
import dayjs from "dayjs";

const TestView = () => {

    const [test, setTest] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const { testId, userId } = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'tests/' + testId + '/' + userId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setTest(response.data.test);
                    setUser(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Candidat inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [navigate, token, testId, userId]);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce candidat seront perdues.")) {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + 'tests/' + testId + '/' + user.id, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Candidat supprimé', toastOptions);
                    navigate(-1);
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{user.firstName} {user.lastName.toUpperCase()}</h1>
                </div>
                <div className='view-buttons'>
                    <button className='main-button return-button margin-right' onClick={() => navigate(-1)} />
                    <a href={process.env.REACT_APP_UPA_INTERFACE_HOST + "tests/" + test.id + '/' + user.id} target="_blank" rel="noreferrer">
                        <button className='main-button blue-button launch margin-right'>Page du candidat</button>
                    </a>
                    <button className='main-button warning-button delete' onClick={handleDelete}>Supprimer</button>
                </div>
            </div>
            <div className='input-row-container'>
                <span className='input-label email'>Email :</span>
                <CustomTag value={user.email} />
            </div>
            <div className='input-row-container'>
                <span className='input-label role'>Nom du test :</span>
                <CustomTag value={test.name} />
            </div>
            <div className='input-row-container'>
                <span className='input-label calendar'>Inscription au test :</span>
                <CustomTag value={dayjs(user.createdAt).format('DD MMMM YYYY à HH:mm')} />
            </div>
            <div className='input-row-container'>
                <span className='input-label calendar'>Passage du test :</span>
                <CustomTag value={dayjs(user.startDate).format('DD MMMM')} />
                <span className='margin-right margin-left'>de</span>
                <CustomTag value={dayjs(user.startDate).format('HH:mm')} />
                <span className='margin-right margin-left'>à</span>
                <CustomTag value={dayjs(user.stopDate).format('HH:mm')} />
            </div>
            <div className='input-row-container'>
                <span className='input-label file'>Dépôt de fichier :</span>
                { user.submittedAt ? (
                    <>
                        <CustomTag value={ 'Déposé à ' + dayjs(user.submittedAt).format('HH:mm')} />
                        <a href={process.env.REACT_APP_UPA_API_HOST + 'tests-uploads/' + user.id + '.zip' }>
                            <div className="main-button download margin-left">Télécharger</div>
                        </a>
                    </>
                ) : (
                    <span>Aucun fichier déposé</span>
                )}
            </div>
        </div>
    ) : (<div className='loader-container'><CircularProgress size={20} className="white-loader" /></div>)
}

export default TestView;
