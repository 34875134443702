import React from "react";
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {ToastContainer} from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import localeFr from 'dayjs/locale/fr';
dayjs.locale(localeFr);

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastContainer />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                <App />
            </LocalizationProvider>
        </BrowserRouter>
    </React.StrictMode>
);
