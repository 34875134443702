import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import InventaireTout from "./tout/InventaireTout";
import GroupView from "./groups/GroupView";
import GroupAdd from "./groups/GroupAdd";
import GroupEdit from "./groups/GroupEdit";
import InventaireSupport from "./support/InventaireSupport";
import ContactView from "./clients/ContactView";

import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import axios from 'axios';
import toastOptions from "../../../assets/constants/toast";

import { EngineProvider } from "../../../contexts/EngineContext";

const Inventaire = () => {
    const [items, setItems] = useState([]);
    const moduleName = 'inventaire';

    return (
        <EngineProvider>
            <Routes>
                <Route path="/clients" element={<PermissionsGate module={moduleName} submodule='tout'><InventaireTout /></PermissionsGate>} />
                <Route path="/clients/:contactId" element={<ContactView projects={items} />} />
                <Route path="/fournisseurs" element={<PermissionsGate module={moduleName} submodule='fournisseurs'><InventaireTout /></PermissionsGate>} />
                <Route path="/groups" element={<PermissionsGate module={moduleName} submodule='groupes'><InventaireTout /></PermissionsGate>} />
                <Route path="/macro" element={<PermissionsGate module={moduleName} submodule='macro'><InventaireTout /></PermissionsGate>} />
                <Route path="/groups/:groupId" element={<GroupView />} />
                <Route path="/groups/:groupId/edit" element={<GroupEdit />} />
                <Route path="/groups/new" element={<GroupAdd />} />
                <Route path="/support" element={<PermissionsGate module={moduleName} submodule='support'><InventaireSupport /></PermissionsGate>} />
                <Route path="*" element={<Navigate to="/clients" />} />
            </Routes>
        </EngineProvider>
    );
}

export default Inventaire;