import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../../../../contexts/AuthContext";
import { toast } from "react-toastify";
import CustomTag from "../../../../custom/CustomTag";
import Tippy from "@tippyjs/react";
import toastOptions from "../../../../../assets/constants/toast";
import CustomList from "../../../../custom/CustomList";
import dayjs from "dayjs";

const ConfigView = ({ setMetasView, contact, refresh, setRefresh }) => {

    const hotel = contact.metasHotels[0];
    const { token } = useContext(AuthContext);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de cet hôtel seront perdues (sauf les réservations).")) {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + 'metas/hotels/' + hotel.id, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    toast.success('Configuration métas supprimée', toastOptions);
                    setRefresh(refresh + 1);
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date',
                icon: 'calendar',
                Cell: ({cell: {value}}) => value ? (<span>{dayjs(value).format('MMMM YYYY')}</span>) : null
            },
            {
                Header: 'Montant HT',
                accessor: 'amountHT',
                icon: 'euro',
                Cell: ({cell: {value}}) => value ? (<CustomTag value={value.toFixed(2) + '€'}/>) : null
            },
            {
                Header: 'Montant TTC facturé',
                accessor: 'amountTTC',
                icon: 'euro',
                Cell: ({cell: {value}}) => value ? (<CustomTag className="credit" value={value.toFixed(2) + '€'}/>) : null
            }
        ],
        []
    );

    const popover = (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return (
        <div className='view-container'>
            <div className='flex-contained'>
                <div>
                    <div className='input-row-container'>
                        <span className="input-label engine">Moteur</span>
                        <CustomTag value={hotel.engineType} />
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label id">Code moteur</span>
                        <CustomTag value={hotel.engineCode} />
                    </div>
                </div>
                <div>
                    <div className='view-header'>
                        <div className='view-buttons'>
                            <button className='main-button blue-button edit' onClick={() => setMetasView('edit')}>Modifier</button>
                            <Tippy content={popover}
                                   allowHTML={true}
                                   placement={'bottom-end'}
                                   offset={[0,5]}
                                   visible={popoverVisible}
                                   onClickOutside={hide}
                                   interactive={true}
                                   appendTo={'parent'}>
                                <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                            </Tippy>
                        </div>
                    </div>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label id">GC Customer</span>
                { hotel.gocardlessCustomer ? (<CustomTag value={hotel.gocardlessCustomer} />) : 'Non renseigné' }
            </div>
            <div className='input-row-container'>
                <span className="input-label id">GC Mandate</span>
                { hotel.gocardlessMandate ? (<CustomTag value={hotel.gocardlessMandate} />) : 'Non renseigné' }
            </div>

            <div className='input-row-container'>
                <span className="input-label euro">Taxe appliquée</span>
                <CustomTag value={ hotel.tax ? hotel.tax.tax_name + ' (' + hotel.tax.tax_percentage + '%)' : 'Non renseignée' } />
            </div>
            { hotel.contacts.length === 0 ? (
                <p className='waiting-order-warning danger'>
                    <span><strong>Attention : </strong>Aucun contact n'a été renseigné pour cet hotel, la facture ne sera donc pas envoyée par email lors de la facturation.</span>
                </p>
            ) : null }
            <div className='metas-invoices-list'>
                <CustomList columns={columns} isSelectable={false} isFetchable={false} entity={hotel.metasInvoices} limit={20} />
            </div>
        </div>
    );
}

export default ConfigView;