import {Navigate, useLocation} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../../contexts/AuthContext";

const PrivateRoute = ({ children }) => {

    const { user } = useContext(AuthContext);
    const location = useLocation();

    if(!user) return <Navigate to="/login" state={{from: location}} replace />

    return children;

}

export default PrivateRoute;