import React from "react";
import {Routes, Route} from "react-router-dom";
import PermissionsGate from "../../PermissionsGate";
import TestList from "./TestList";
import TestAdd from "./TestAdd";
import TestView from "./TestView";
import TestEdit from "./TestEdit";
import TestUser from "./TestUser";

const Tests = () => {
    return (
        <PermissionsGate module="hiring" >
            <Routes>
                <Route path="new" element={<TestAdd />} />
                <Route path=":testId" element={<TestView />} />
                <Route path=":testId/edit" element={<TestEdit />} />
                <Route path=":testId/:userId" element={<TestUser />} />
                <Route path="*" element={<TestList />} />
            </Routes>
        </PermissionsGate>
    );
}

export default Tests;