import React, {useContext, useState} from "react";
import AuthContext from "../../../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import CustomSearchInput from "../../../custom/CustomSearchInput";
import {CircularProgress, Switch} from "@mui/material";

const GroupAdd = () => {

    const [group, setGroup] = useState({ shared: false });
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'books/groups' , group, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Le groupe a bien été ajouté", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    return (
        <div>
            <div className='list-header'>
                <div className='view-title'>
                    <h1>Ajouter un groupe</h1>
                </div>
                <div className='view-buttons'>
                    <button onClick={() => navigate(-1)} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button plus margin-left' onClick={handleCreate} disabled={buttonLoading}>{ buttonLoading ? <CircularProgress size={20} className="white-loader" /> : 'Ajouter' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className="input-label id">Nom du groupe <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={group.name ? group.name : ''} className='classic-input' placeholder='Entrez un nom' onChange={(e) => {
                            setGroup((group) => { return { ...group, name:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label role">Mutualisé <span className='required'>*</span></span>
                    <div className='input-container without-icon switch'>
                        <Switch checked={group.shared} onChange={(e) => setGroup({...group, shared: e.target.checked})} />
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label user'>Hôtels <span className='required'>*</span></span>
                    <div className='input-container'>
                        <CustomSearchInput placeholder='Rechercher un hôtel'
                                           entity='books/customers'
                                           value={group.contacts}
                                           labelType='zohoContact'
                                           isMultiSearch
                                           onChange={(newValue) => { setGroup((group) => { return { ...group, contacts: newValue }})}}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupAdd;
