const constants = {};

constants.roles = [
    {
        value: "accountant",
        id: "accountant",
        label: "Comptable",
        modules: ["accounting"],
    },
    {
        value: "recruiter",
        id: "recruiter",
        label: "Recruteur",
        modules: ["hiring"],
    },
    {
        value: "project-manager",
        id: "project-manager",
        label: "Chef de projet",
        modules: ["projects", "accounting"],
    },
    {
        value: "admin",
        id: "admin",
        label: "Administrateur",
        modules: ["accounting", "settings", "hiring", "projects"],
    },
];

constants.modules = [
    {
        value: "accounting",
        label: "Comptabilité",
        submodules: [
            { value: "transactions", label: "À classer" },
            { value: "contacts", label: "Contacts" },
            { value: "exports", label: "Exports" },
            { value: "logged-transactions", label: "Transactions" },
            { value: "metas-billing", label: "Facturation métas" },
            { value: "debugger", label: "Debugger" },
            { value: "pennylane-subscription", label: "Abonnements Pennylane" },
        ],
    },
    {
        value: "hiring",
        label: "Recrutement",
        submodules: [{ value: "tests", label: "Tests" }],
    },
    {
        value: "projects",
        label: "Projets",
        submodules: [
            { value: "view", label: "Page projet" },
            { value: "ongoing", label: "En cours" },
            { value: "closed", label: "Terminés" },
            { value: "internal", label: "Internes" },
            { value: "chatbot", label: "Chatbot" },
        ],
    },
    {
        value: "inventaire",
        label: "Inventaire",
        submodules: [
            { value: "clients", label: "Clients" },
            { value: "fournisseurs", label: "Fournisseurs" },
            { value: "groups", label: "Groupes" },
            { value: "macro", label: "Macro" },
            { value: "support", label: "Support" },
        ],
    },
    {
        value: "chatbot",
        label: "Chatbot",
        submodules: [
            { value: "extract", label: "Extractions" },
            { value: "translate", label: "Traductions" },
            { value: "souscription", label: "Souscription" },
            { value: "facturation", label: "Facturation" },
        ],
    },
    {
        value: "users",
        label: "Utilisateurs",
        submodules: [],
    },
    {
        value: "roles",
        label: "Rôles",
        submodules: [],
    },
];

constants.engineTypes = [
    { value: "dedge-smart", label: "D-EDGE - Smart" },
    { value: "dedge-attraction", label: "D-EDGE - Attraction" },
    { value: "reservit", label: "Reserv'it" },
];

constants.engineSources = [
    { value: "website", label: "Site internet" },
    { value: "googlehpa", label: "Métas - Google" },
];

constants.status = [
    { value: "active", label: "Actif" },
    { value: "inactive", label: "Inactif" },
    { value: "archived", label: "Archivé" },
    { value: "progress", label: "En cours" },
    { value: "archived", label: "Archivé" },
    { value: "draft", label: "Non commencé" },
    { value: "waiting", label: "En attente" },
    { value: "closed", label: "Terminé" },
    { value: "difficulty", label: "En difficulté" },
    { value: "accepted", label: "Acceptée" },
    { value: "refused", label: "Refusée" },
    { value: "paid", label: "Congé payé" },
    { value: "unpaid", label: "Congé non payé" },
];

constants.types = [
    { value: "new", label: "NOUVEAU CLIENT" },
    { value: "no-assistance", label: "PAS D'ASSISTANCE" },
    { value: "essential", label: "ASSISTANCE ESSENTIEL" },
    { value: "premium", label: "ASSISTANCE PREMIUM" },
];

constants.frequencies = [
    { value: "days", label: "jours" },
    { value: "weeks", label: "semaines" },
    { value: "months", label: "mois" },
];

constants.dataTypes = [
    { value: "order", label: "Lancement" },
    { value: "project", label: "Projet" },
    { value: "customer", label: "Client" },
    { value: "provider", label: "Fournisseur" },
];

constants.deeplLanguages = [
    { code: "FR", language: "French" },
    { code: "EN", language: "English" },
    { code: "ES", language: "Spanish" },
    { code: "IT", language: "Italian" },
    { code: "AR", language: "Arabic" },
    { code: "BG", language: "Bulgarian" },
    { code: "CS", language: "Czech" },
    { code: "DA", language: "Danish" },
    { code: "DE", language: "German" },
    { code: "EL", language: "Greek" },
    { code: "ET", language: "Estonian" },
    { code: "FI", language: "Finnish" },
    { code: "HU", language: "Hungarian" },
    { code: "ID", language: "Indonesian" },
    { code: "JA", language: "Japanese" },
    { code: "KO", language: "Korean" },
    { code: "LT", language: "Lithuanian" },
    { code: "LV", language: "Latvian" },
    { code: "NB", language: "Norwegian" },
    { code: "NL", language: "Dutch" },
    { code: "PL", language: "Polish" },
    { code: "PT", language: "Portuguese" },
    { code: "RO", language: "Romanian" },
    { code: "RU", language: "Russian" },
    { code: "SK", language: "Slovak" },
    { code: "SL", language: "Slovenian" },
    { code: "SV", language: "Swedish" },
    { code: "TR", language: "Turkish" },
    { code: "UK", language: "Ukrainian" },
    { code: "ZH", language: "Chinese" },
];

constants.all = constants.roles.concat(constants.status);
constants.all = constants.all.concat(constants.modules);
constants.all = constants.all.concat(constants.types);
constants.all = constants.all.concat(constants.frequencies);
constants.all = constants.all.concat(constants.engineTypes);
constants.all = constants.all.concat(constants.engineSources);
constants.all = constants.all.concat(constants.dataTypes);

export default constants;
