import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import ProjectList from "./ProjectList";
import ProjectView from "./ProjectView";
import ClosedProjects from "./ClosedProjects";

const Projects = () => {

    const moduleName = 'projects';

    return (
            <Routes>
                <Route path=":projectId" element={<PermissionsGate module={moduleName} submodule='view'><ProjectView /></PermissionsGate>} />
                <Route path="/ongoing" element={<PermissionsGate module={moduleName} submodule='ongoing'><ProjectList /></PermissionsGate>} />
                <Route path="/closed" element={<PermissionsGate module={moduleName} submodule='closed'><ClosedProjects /></PermissionsGate>} />
                <Route path="/internal" element={<PermissionsGate module={moduleName} submodule='internal'><ProjectList projectType='internal' /></PermissionsGate>} />
                <Route path="*" element={<Navigate to='ongoing' />} />
            </Routes>
    );
}

export default Projects;
