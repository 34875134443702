import React from "react";
import CustomTag from "./CustomTag";

const CustomNavbarOption = ({ innerProps, isDisabled, label, ...props }) => {

    const customer = props.data;

    return (
        <>
            <div className='navbar-option-container'>
                <div {...innerProps} className='custom-tag-container'>
                    <div className='navbar-customer-icon'>
                        <img src={customer.logoUrl ? customer.logoUrl : 'https://image.store.altelis.com/images/altelis-tools/logo-altelis.png'} alt='navbar-logo' />
                    </div>
                    <div>
                        <span className='navbar-title'>{customer.data.contact_name}</span>
                        <div className='navbar-tags'>
                            <CustomTag value={customer.data.status} className={' margin-left ' + customer.data.status} />
                            <CustomTag value={customer.supportType} className=" margin-left" />
                            { customer.group ? (
                                <CustomTag value={customer.group.name} className=' margin-left' />
                            ) : null }
                        </div>
                    </div>
                </div>
            </div>
            <div className='navbar-divider'/>
        </>
    );
}

export default CustomNavbarOption;