import React from "react";

const ContactContacts = ({ contact }) => {

    const checkupDoublon = (str) => str.toLowerCase().trim();

    const formatNumeroTelephone = (number) => {
        if (!number) return 'Non renseigné';
        return number.replace(/(\d{2})(?=\d)/g, '$1 ').trim();
    };

    const formatMajuscule = (lastName) => lastName.toUpperCase();
    const formatNomComplet = (firstName, lastName) => {
        if (!firstName && !lastName) return 'Non renseigné';
        return `${firstName || 'Non renseigné'} ${formatMajuscule(lastName)}`;
    };
    const formatEmail = (email) => email || 'Non renseigné';

    const contactPrincipal = contact.contactsAvailable.find(c => c.is_primary_contact);
    const autresContacts = contact.contactsAvailable.filter(c =>
        !c.is_primary_contact && !(checkupDoublon(c.first_name) === checkupDoublon(contactPrincipal.first_name) && checkupDoublon(c.last_name) === checkupDoublon(contactPrincipal.last_name))
    );

    return (
        <div className="contacts__container">
            {contactPrincipal && (
                <div className="contacts__contact">
                    <div className="contacts__header">
                        <h2 className="contact__titre">Contact principal</h2>
                        <div className="contacts__button">
                            <button>Modifier</button>
                            <button>...</button>
                        </div>
                    </div>
                    <div className="contact__wrapper">
                        <h3 className="contact__titre">{formatNomComplet(contactPrincipal.first_name, contactPrincipal.last_name)}</h3>
                        <p className="contact__info contact__info--email">Adresse mail : <span>{formatEmail(contactPrincipal.email)}</span></p>
                        <p className="contact__info contact__info--tel">Téléphone fixe : <span>{formatNumeroTelephone(contactPrincipal.phone)}</span></p>
                        <p className="contact__info contact__info--tel">Mobile : <span>{formatNumeroTelephone(contactPrincipal.mobile)}</span></p>
                    </div>
                </div>
            )}

            {autresContacts.length > 0 && (
                <div className="contacts__contact">
                    <div className="contacts__header">
                        <h2 className="contact__titre">Autres contacts</h2>
                        <div className="contacts__button">
                            <button>Modifier</button>
                            <button>...</button>
                        </div>
                    </div>
                    <div className="contacts__grid">
                        {autresContacts.map((c, index) => (
                            <div key={index} className="contact__wrapper">
                                <h3 className="contact__titre">{formatNomComplet(c.first_name, c.last_name)}</h3>
                                <p className="contact__info contact__info--email">Adresse mail : <span>{formatEmail(c.email)}</span></p>
                                <p className="contact__info contact__info--tel">Téléphone fixe : <span>{formatNumeroTelephone(c.phone)}</span></p>
                                <p className="contact__info contact__info--tel">Mobile : <span>{formatNumeroTelephone(c.mobile)}</span></p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactContacts;