import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import favicon from "../../../assets/images/project-favicon.png";
import CustomTag from "../../custom/CustomTag";
import CustomList from "../../custom/CustomList";
import dayjs from "dayjs";
import ProjectCategories from "./ProjectCategories";

const ProjectView = () => {

    const [project, setProject] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [billedTasks, setBilledTasks] = useState([]);
    const [nonBilledTasks, setNonBilledTasks] = useState([]);
    const [costByMember, setCostByMember] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [tab, setTab] = React.useState(1);
    const { token } = useContext(AuthContext);
    const { projectId } = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectId, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => {
                if (response.data) {
                    console.log("Projet récupéré:", response.data);
                    setProject(response.data.data);
                    setTasks(response.data.tasks);
                    setNonBilledTasks(response.data.tasks.filter(task => response.data.data.categories.every(category => category.id !== task.data.categoryId)));
                    const billedTasks = response.data.tasks.filter(task => response.data.data.categories.some(category => category.id === task.data.categoryId));
                    setBilledTasks(billedTasks);
                    const array = [];
                    for (const task of billedTasks) {
                        let member = array.find(member => member.name === task.data.member)
                        if (!member) {
                            array.push({ name: task.data.member, cost: 0, hours: 0 })
                        }
                        member = array.find(member => member.name === task.data.member);
                        member.cost += task.data.cost;
                        member.hours += task.data.hours;
                    }
                    setCostByMember(array.sort((a, b) => b.cost - a.cost));
                    setIsLoaded(true);
                } else {
                    toast.error('Projet inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [navigate, token, projectId]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Membre",
                icon: "user",
                accessor: "data.member",
                Cell: ({ value }) => (<span>{value}</span>)
            },
            {
                Header: "Tâche",
                icon: "role",
                accessor: "data.name",
                Cell: ({ value }) => (<CustomTag value={value} />)
            },
            {
                Header: "Temps",
                icon: "role",
                accessor: "data.hours",
                Cell: ({ value }) => (<span>{value} heure(s)</span>)
            },
            {
                Header: "Coût",
                icon: "euro",
                accessor: "data.cost",
                Cell: ({ value }) => (<CustomTag value={formatAmount(value) + ' €'} />)
            },
            {
                Header: "Date",
                accessor: "data.date",
                icon: "calendar",
                Cell: ({ value }) => (<span>{dayjs(value).format('DD MMMM YYYY')}</span>)
            }
        ], []);

    const formatAmount = (amount, round = 2) => {
        return Number(amount).toLocaleString('fr-FR', { maximumFractionDigits: round, minimumFractionDigits: round });
    }

    return isLoaded ? (
        <div className="view-container project-view">
            <div className="view-header">
                <div className="view-title">
                    <h1>
                        {project.iconEmoji ? (
                            <span className="margin-right">{project.iconEmoji}</span>
                        ) : ((project.iconFile ? (
                            <img src={project.iconFile} alt="project-icon" className="margin-right" width={30} />
                        ) : (
                            <img src={favicon} alt="project-icon" className="margin-right" width={30} />
                        )))}
                        {project.name ? project.name : "Non renseigné"}
                    </h1>
                </div>
            </div>
            <div className="project-card">
                <ProjectCategories project={project} costByMember={costByMember} />
            </div>
            <div className="project-card-row">
                <div className="project-totals-card">
                    <span className="title">{formatAmount(project.totalHours, 0)}</span>
                    <small>h. travaillées</small>
                </div>
                <div className="project-totals-card">
                    <span className="title">{project.workingDaysElapsed}</span>
                    <small>jours écoulés</small>
                </div>
                <div className="project-totals-card">
                    <span className="title">{formatAmount(project.margin, 0)} €</span>
                    <small>de marge</small>
                </div>
                <div className="project-totals-card">
                    <span className="title">{tasks.length}</span>
                    <small>saisies</small>
                </div>
            </div>
            <div className="tabs-container">
                <Tabs value={tab} onChange={(event, value) => setTab(value)} >
                    <Tab value={0} label={"Tout (" + tasks.length + ")"} />
                    <Tab value={1} label={"Tâches facturées (" + billedTasks.length + ")"} />
                    <Tab value={2} label={"Tâches non facturées (" + nonBilledTasks.length + ")"} />
                </Tabs>
                <div role="tabpanel" id={0} hidden={tab !== 0} >
                    <CustomList entity={tasks} columns={columns} isSelectable={false} isClickable={false} isFetchable={false} isDownloadable />
                </div>
                <div role="tabpanel" id={1} hidden={tab !== 1}>
                    <CustomList entity={billedTasks} columns={columns} isSelectable={false} isClickable={false} isFetchable={false} isDownloadable />
                </div>
                <div role="tabpanel" id={2} hidden={tab !== 2}>
                    <CustomList entity={nonBilledTasks} columns={columns} isSelectable={false} isClickable={false} isFetchable={false} isDownloadable />
                </div>
            </div>
        </div>
    ) : <div className="loader-container"><CircularProgress size={30} /></div>
}

export default ProjectView;
