import React from "react";
import CustomList from "../../../custom/CustomList";
import CustomTag from "../../../custom/CustomTag";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const TestList = () => {

    const columns = React.useMemo(
        () => [
            {
                Header: "Nom du test",
                accessor: "name",
                icon: "pen"
            },
            {
                Header: "Début du test",
                accessor: "startDate",
                icon: "calendar",
                Cell: ({ cell: { value } }) => (<CustomTag value={dayjs(value).format('DD MMMM YYYY')} />)
            },
            {
                Header: "Fin du test",
                accessor: "stopDate",
                icon: "calendar",
                Cell: ({ cell: { value } }) => (<CustomTag value={dayjs(value).format('DD MMMM YYYY')} />)
            }
        ], []);

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title tests">
                    <h1>Tests</h1>
                </div>
                <div className="header-buttons">
                    <Link to="new">
                        <button className="main-button blue-button plus">Créer un test</button>
                    </Link>
                </div>
            </div>
            <div className="tabs-container">
                <CustomList entity={"tests"} columns={columns} />
            </div>
        </div>
    )
}

export default TestList;