import LinearProgress from "@mui/material/LinearProgress";
import {Grid} from "@mui/material";
import React from "react";
import CustomList from "../../custom/CustomList";

const ProjectCategories = ({project, costByMember}) => {

    const formatAmount = (amount) => {
        return Number(amount).toLocaleString('fr-FR', {maximumFractionDigits: 0, minimumFractionDigits: 0});
    }

    const formatDaysIfNeeded = hours => {
        if(hours > 7) {
            return Number(hours / 7).toLocaleString('fr-FR', {maximumFractionDigits: 2, minimumFractionDigits: 0}) + ' j.'
        }
        return Number(hours).toLocaleString('fr-FR', {maximumFractionDigits: 0, minimumFractionDigits: 0}) + ' h.'
    }

    const categoriesCols = React.useMemo(() => [
        { Header: 'Catégorie', accessor: 'name', icon: 'user' },
        { Header: 'Coût', accessor: 'cost', icon: 'user', Cell: ({cell: {value}}) => <span>{formatAmount(value)} €</span> },
        { Header: 'Temps', accessor: 'hours', icon: 'euro', Cell: ({cell: {value}}) => <span>{formatDaysIfNeeded(value)}</span>},
        { Header: '%', accessor: 'cost', id: 'percentage', icon: 'euro', Cell: ({cell: {value}}) => <span>{formatAmount((value / project.billedCost) * 100)} %</span> }
    ], []);

    const membersCols = React.useMemo(() => [
        { Header: 'Membre', accessor: 'name', icon: 'user' },
        { Header: 'Coût', accessor: 'cost', icon: 'user', Cell: ({cell: {value}}) => <span>{formatAmount(value)} €</span> },
        { Header: 'Temps', accessor: 'hours', icon: 'euro', Cell: ({cell: {value}}) => <span>{formatDaysIfNeeded(value)}</span>},
        { Header: '%', accessor: 'cost', id: 'percentage', icon: 'euro', Cell: ({cell: {value}}) => <span>{formatAmount((value / project.billedCost) * 100)} %</span> }
    ], []);

    return project.type !== 'Interne' ? (
        <div>
            { project.estimates.length > 0 && ((Math.abs(project.estimateDiffWithBudget) / project.budget) * 100 > 2) ? (
                <div className='project-warning-estimation'>
                    <small>Attention: L'estimation rentrée dans Notion ({project.budget - project.estimateDiffWithBudget}€) est {project.estimateDiffWithBudget < 0 ? 'supérieure' : 'inférieure'} de {Math.abs(project.estimateDiffWithBudget)}€ ({Math.round((Math.abs(project.estimateDiffWithBudget) / project.budget) * 100) } %) par rapport au budget vendu.</small>
                </div>
            ) : null }
            { project.estimates.length === 0 ? (
                <div className='project-warning-estimation'>
                    <small>Attention: Aucune estimation saisie pour ce projet.</small>
                </div>
            ) : null }
            <div className='budget-container main-budget'>
                <div className={'progress-bar-container ' + (project.costPercentage >= 50 ? (project.costPercentage > 100 ? 'budget-danger' : 'budget-warning') : '')}>
                    <span className="total-cost-badge">{formatAmount(project.billedCost)} € - {formatAmount(project.costPercentage)} %</span>
                    <LinearProgress variant="determinate" className="budget-progress" value={project.costPercentage > 100 ? (project.costPercentage > 200 ? 100 : project.costPercentage - 100) : project.costPercentage} />
                </div>
                <span className="budget-badge">{project.budget ? formatAmount(project.budget) + ' €' : "Non renseigné"}</span>
            </div>
            <div className="categories-data-container">
                <Grid container spacing={4}>
                    <Grid item xs={costByMember ? 6 : 12}>
                        { project.billedCost > 0 ? project.categories.map((category, index) => (
                            <Grid container spacing={2} key={index} className={category.categoryCost === 0 ? 'inactive' : ''}>
                                <Grid item xs={6}>
                                    <span className="category-title">{category.name}</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={'budget-container ' + (!project.isEstimated ? 'not-estimated' : '')}>
                                        <div className={'progress-bar-container ' + (category.costPercentage >= 50 ? (category.costPercentage > 100 ? 'danger' : 'warning') : '')}>
                                            <span className="total-cost-badge">{formatAmount(category.categoryCost)}{project.isEstimated ? ' / ' + formatAmount(category.estimateCost) : null} € - {formatAmount(category.costPercentage)} %</span>
                                            <LinearProgress variant="determinate" className="budget-progress" value={category.costPercentage > 100 ? (category.costPercentage > 200 ? 100 : category.costPercentage - 100) : category.costPercentage} />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        )) : <span className="no-cost">Aucun coût renseigné pour ce projet.</span> }
                    </Grid>
                    { costByMember ? (
                        <Grid item xs={6}>
                            <div className='cost-by-member'>
                                <div className='small-list-container'>
                                    <CustomList
                                        entity={costByMember}
                                        isFetchable={false}
                                        isPaginable={false}
                                        isClickable={false}
                                        isSelectable={false}
                                        columns={membersCols}
                                    />
                                </div>
                            </div>
                        </Grid>
                    ) : null }
                </Grid>
            </div>
        </div>
    ) : (
        <div className="categories-data-container">
            { project.categories.map((category, index) => (
                <Grid container spacing={2} key={index}>
                    <Grid item xs={6}>
                        <span>{category.name}</span>
                    </Grid>
                    <Grid item xs={6}>
                        <div className='internal-cost'>
                            <span className="">{formatAmount(category.categoryCost)} €</span>
                        </div>
                    </Grid>
                </Grid>
            ))}
            <div className='internal-totals'>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <span>Total</span>
                    </Grid>
                    <Grid item xs={6}>
                        <div className='internal-cost'>
                            <span className="">{formatAmount(project.totalCost)} €</span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default ProjectCategories;