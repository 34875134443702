import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import CustomTag from "../../../custom/CustomTag";
import { CircularProgress, IconButton, Tab, Tabs } from "@mui/material";
import toastOptions from "../../../../assets/constants/toast";
import CustomList from "../../../custom/CustomList";
import MetasConfigView from "../../accounting/contacts/metas/ConfigView";
import MetasConfigAdd from "../../accounting/contacts/metas/ConfigAdd";
import MetasConfigEdit from "../../accounting/contacts/metas/ConfigEdit";
import EngineConfigView from "../../accounting/contacts/engines/ConfigView";
import EngineConfigAdd from "../../accounting/contacts/engines/ConfigAdd";
import EngineConfigEdit from "../../accounting/contacts/engines/ConfigEdit";
import ContactSupport from "./ContactSupport";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { toast } from "react-toastify";
import FormData from "form-data";
import ContactInfos from "./ContactInfos";
import ContactChatbot from "./ContactChatbot";
import ContactContacts from "./ContactContacts";

const ContactView = () => {

    const { contactId } = useParams();
    const [contact, setContact] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [edit, setEdit] = useState({ website: false });
    const [tab, setTab] = useState(0);
    const [metasView, setMetasView] = useState('view');
    const [enginesView, setEnginesView] = useState({ name: 'view', params: null });
    const [refresh, setRefresh] = useState(0);
    const { token } = useContext(AuthContext);
    const [contrat, setContrat] = useState(0);
    const [projectsLoaded, setProjectsLoaded] = useState(false);
    const [projects, setProjects] = useState([]);
    const abonnementLivie = (contact?.recurringLines ?? []).some(line => line.item_id === '180635000004769204');

    useEffect(() => {
        setIsLoaded(false);
        axios.get(`${process.env.REACT_APP_UPA_API_HOST}books/contacts/${contactId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data) {
                    console.log("Récupération des datas Books Contacts : ", response.data);
                    setContact(response.data);
                    setMetasView(response.data.metasHotels.length > 0 ? 'view' : null);
                    setEnginesView({ name: response.data.engineConfigs.length > 0 ? 'view' : null, params: response.data.engineConfigs.length > 0 ? response.data.engineConfigs[0] : null });
                    setIsLoaded(true);
                }
            }).catch((error) => console.log(error));
    }, [refresh, contactId, token]);

    useEffect(() => {
        setProjectsLoaded(false);
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects/', { headers: { Authorization: 'Bearer ' + token } })
            .then(response => {
                setProjects(response.data);
                setProjectsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setProjectsLoaded(true);
            });
    }, [token]);

    useEffect(() => {
        if (projects.length > 0 && contact?.data) {
            const planComptable = contact.data.cf_n_du_compte_plan_comptable;
            if (typeof planComptable === 'string' && planComptable.length) {
                const idAltelisBooks = planComptable.slice(-3);
                const matchingProject = projects.find(project =>
                    project.data.idProjet && project.data.idProjet.toString().endsWith(idAltelisBooks));

                if (matchingProject && matchingProject.data.contrat && matchingProject.data.contrat.length > 0) {
                    const contrat = matchingProject.data.contrat[0].url;
                    setContrat(contrat);
                    console.log(contrat)
                } else {
                    console.log("Aucun contrat trouvé ou liste de contrats vide");
                }
            } else {
                console.log("Impossible de récupérer la propriété 'cf_n_du_compte_plan_comptable'");
            }
        }
    }, [projects, contact?.data]);

    const selectedEngineConfig = enginesView.params;

    const handleUpdate = (logoUrl = null) => {
        console.log({
            websiteUrl: contact.websiteUrl,
            logoUrl: logoUrl ? logoUrl : contact.logoUrl
        });
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'books/contacts/' + contactId, {
            websiteUrl: contact.websiteUrl,
            logoUrl: logoUrl ? logoUrl : contact.logoUrl
        }, { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => {
                if (response.data) {
                    setRefresh(refresh + 1);
                    setEdit({ ...edit, website: false });
                    toast.success('Contact mis à jour avec succès', toastOptions);
                }
            }).catch((error) => console.log(error));
    }

    const recurringCols = React.useMemo(
        () => [
            { Header: 'Article / Produit', accessor: 'name', icon: 'article', Cell: ({ cell: { value } }) => <CustomTag value={value} /> },
            {
                Header: 'Récurrence',
                icon: 'calendar',
                Cell: row => {
                    const frequence = row.row.original.repeat_every;
                    const unite = row.row.original.recurrence_frequency;
                    let traductionFrancais;
                    switch (unite) {
                        case 'days':
                            traductionFrancais = 'jour(s)';
                            break;
                        case 'weeks':
                            traductionFrancais = 'semaine(s)';
                            break;
                        case 'months':
                            traductionFrancais = 'mois';
                            break;
                        case 'years':
                            traductionFrancais = 'an(s)';
                            break;
                        default:
                            traductionFrancais = unite;
                    }
                    const repeatText = frequence > 1 ? `Tous les ${frequence} ${traductionFrancais}` : `Tous les ${traductionFrancais}`;
                    return <span>{repeatText}</span>;
                }
            }
            , { Header: 'Montant facturé', accessor: 'item_total', icon: 'conso', Cell: ({ cell: { value } }) => value ? (<CustomTag className="custom-tag" value={value.toFixed(2) + '€'} />) : null }
        ], []);

    const totalAmount = contact && contact.recurringLines ? contact.recurringLines.reduce((total, line) => total + (line.item_total || 0), 0) : 0;

    const renderMetas = () => {
        switch (metasView) {
            case 'view':
                return <MetasConfigView setMetasView={setMetasView} contact={contact} refresh={refresh} setRefresh={setRefresh} />;
            case 'edit':
                return <MetasConfigEdit setMetasView={setMetasView} contact={contact} refresh={refresh} setRefresh={setRefresh} />;
            case 'add':
                return <MetasConfigAdd setMetasView={setMetasView} contact={contact} zohoId={contact.id} refresh={refresh} setRefresh={setRefresh} />;
            default:
                return (
                    <div className='tab-container'>
                        <CustomTag className="margin-bottom" value='Ce contact ne possède pas de configuration métas' />
                        <button className='main-button blue-button plus' onClick={() => setMetasView('add')}>Ajouter une configuration métas</button>
                    </div>
                );
        }
    }

    const renderEngine = () => {
        switch (enginesView.name) {
            case 'edit':
                return <EngineConfigEdit setEnginesView={setEnginesView} configToEdit={enginesView.params} refresh={refresh} setRefresh={setRefresh} />;
            case 'add':
                return <EngineConfigAdd setEnginesView={setEnginesView} contact={contact} zohoId={contact.id} refresh={refresh} setRefresh={setRefresh} />;
            default:
                return contact.engineConfigs.length > 0 ? (
                    contact.engineConfigs.map((config, index) => <EngineConfigView key={index} setEnginesView={setEnginesView} config={config} refresh={refresh} setRefresh={setRefresh} />)
                ) : (
                    <div className='tab-container'>
                        <CustomTag className="margin-bottom" value='Ce contact ne possède pas de configuration moteur' />
                        <button className='main-button blue-button plus' onClick={() => setEnginesView({ name: 'add' })}>Ajouter une configuration moteur</button>
                    </div>
                );
        }
    }

    const uploadNewLogo = (event) => {
        if (event.target.files[0]) {
            let data = new FormData();
            const extension = event.target.files[0].name.split('.').pop();
            data.append('path', '/altelis-tools/contacts/' + contact.id + '.' + extension);
            data.append('image', event.target.files[0]);
            axios.post('https://image.store.altelis.com/api/v1/upload', data)
                .then(response => {
                    handleUpdate('https://image.store.altelis.com/' + response.data.path);
                })
                .catch(err => console.log(err));
        }
    };

    return isLoaded ? (
        <div className='view-container'>
            <div className='customer-view-header'>
                <div className='customer-view-data'>
                    <label htmlFor='file' className='customer-view-logo'>
                        {contact.logoUrl ? (
                            <img src={contact.logoUrl} alt='customer-logo' />
                        ) : (
                            <img src='https://image.store.altelis.com/images/altelis-tools/logo-altelis.png' alt='customer-logo' />
                        )}
                        <input accept="image/png, image/jpeg" type="file" name="file" id='file' onChange={uploadNewLogo} />
                    </label>
                    <div className="customer-view-wrapper">
                        <div className='customer-view-title'>
                            <h1>{contact.data.contact_name}</h1>
                            {typeof contact.data.cf_n_du_compte_plan_comptable === 'string' && (
                                <span> - {contact.data.cf_n_du_compte_plan_comptable.slice(-3)}</span>
                            )}
                        </div>
                        <div className='customer-view-details'>
                            <div className='input-row-container'>
                                <span className="input-label small-input statut">Statut</span>
                                <CustomTag value={contact.data.status ? contact.data.status : 'Non renseigné'} className={(contact.data.status ? '' : 'deactivated')} />
                            </div>
                            <div className='input-row-container'>
                                <span className="input-label small-input groupe">Groupe</span>
                                {contact.group ? (
                                    <Link to={'/admin/inventaire/groups/' + contact.group.id}>
                                        <CustomTag value={contact.group.name} className="" />
                                    </Link>
                                ) : (
                                    <CustomTag value="Aucun" className="deactivated" />
                                )}
                            </div>
                            <div className='input-row-container'>
                                <span className="input-label small-input link">Lien du site</span>
                                {edit.website ? (
                                    <div className="input-container without-icon">
                                        <input value={contact.websiteUrl ? contact.websiteUrl : ''} className='classic-input margin-right' placeholder='Entrez une URL' onChange={e =>
                                            setContact({ ...contact, websiteUrl: e.target.value })} />
                                        <IconButton aria-label="delete" size="small" onClick={() => {
                                            setRefresh(refresh + 1);
                                            setEdit({ ...edit, website: false });
                                        }}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton aria-label="delete" size="small" onClick={() => handleUpdate()}>
                                            <DoneIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <>
                                        <a href={'https://' + contact.websiteUrl} target='_blank' rel="noreferrer" >
                                            <CustomTag value={contact.websiteUrl ? contact.websiteUrl : 'Non renseigné'} className={'margin-right ' + (contact.websiteUrl ? '' : 'deactivated')} />
                                        </a>
                                        <IconButton aria-label="delete" size="small" onClick={() => setEdit({ ...edit, website: true })}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </>
                                )}
                            </div>
                            <div className='input-row-container'>
                                <span className="input-label small-input doc">Contrat</span>
                                {contrat ? (
                                    <Link to={contrat} target="_blank">
                                        <CustomTag value="Voir le contrat" />
                                    </Link>
                                ) : (
                                    <CustomTag value="Aucun" className="deactivated" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tabs-container'>
                <Tabs value={tab} onChange={(event, value) => setTab(value)} >
                    <Tab value={0} label="Infos Générales" />
                    <Tab value={1} label="Contacts" />
                    <Tab value={2} label={(
                        <span className={'contact-dot ' + contact.supportType}>Support</span>
                    )} />
                    <Tab value={3} label={(
                        <span className={contact.recurringLines ? 'contact-dot active' : 'contact-dot inactive'}>Facturation récurrente</span>
                    )} />
                    <Tab value={4} label={(
                        <span className={contact.engineConfigs.length > 0 ? 'contact-dot active' : 'contact-dot inactive'}>Moteur</span>
                    )} />
                    <Tab value={5} label={(
                        <span className={contact.metasHotels.length > 0 ? 'contact-dot active' : 'contact-dot inactive'}>Pub</span>
                    )} />
                    <Tab value={6} label={(
                        <span className={`contact-dot ${abonnementLivie ? 'active' : 'inactive'}`}>Chatbot</span>
                    )} />
                </Tabs>
                <div role="tabpanel" value={0} hidden={tab !== 0} >
                    <ContactInfos contactId={contactId} contact={contact} websiteUrl={contact.websiteUrl} engineConfig={selectedEngineConfig} />
                </div>
                <div role="tabpanel" value={1} hidden={tab !== 1} >
                    <ContactContacts
                        contact={contact}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                </div>
                <div role="tabpanel" value={2} hidden={tab !== 2} >
                    <ContactSupport contact={contact} setContact={setContact} refresh={refresh} setRefresh={setRefresh} />
                </div>
                <div role="tabpanel" value={3} hidden={tab !== 3}>
                    {contact && contact.recurringLines ? (
                        <>
                            <CustomList columns={recurringCols} isSelectable={false} isClickable={false} isFetchable={false} isPaginable={false} entity={contact.recurringLines} limit={1000} />
                            <div className="total-amount-wrapper">
                                <div className="total-amount">Total des montants facturés : {totalAmount.toFixed(2)}€</div>
                            </div>
                        </>
                    ) : (
                        <div className='tab-container'>
                            <CustomTag value='Aucune facturation récurrente' />
                        </div>
                    )}
                </div>
                <div role="tabpanel" value={4} hidden={tab !== 4}>
                    {renderEngine()}
                </div>
                <div role="tabpanel" value={5} hidden={tab !== 5}>
                    {renderMetas()}
                </div>
                <div role="tabpanel" value={6} hidden={tab !== 6}>
                    <ContactChatbot contact={contact} abonnementLivie={abonnementLivie} />
                </div>
            </div>
        </div>
    ) : (<div className='loader-container'><CircularProgress size={30} /></div>)
}

export default ContactView;