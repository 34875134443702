import React, { useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import AuthContext from "../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";

const PennylaneSubscription = () => {
    const [isExporting, setIsExporting] = useState(false);
    const { token } = useContext(AuthContext);

    const downloadCSV = async () => {
        setIsExporting(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_UPA_API_HOST}pennylane/billing-subscriptions`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: "blob",
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const currentDate = new Date();
            const formattedDate = currentDate
                .toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })
                .replace(/\//g, "_");
            link.setAttribute(
                "download",
                `abonnements_pennylane_${formattedDate}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();

            toast.success("Fichier téléchargé avec succès.", toastOptions);
        } catch (error) {
            toast.error(
                "Erreur lors du téléchargement du fichier",
                toastOptions
            );
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title pennylane">
                    <h1>Abonnements Pennylane</h1>
                </div>
            </div>
            <button
                className="main-button download"
                onClick={downloadCSV}
                disabled={isExporting}
            >
                {isExporting ? (
                    <CircularProgress size={24} />
                ) : (
                    "Exporter les abonnements"
                )}
            </button>
        </div>
    );
};

export default PennylaneSubscription;
