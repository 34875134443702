import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../../../../contexts/AuthContext";
import { toast } from "react-toastify";
import CustomTag from "../../../../custom/CustomTag";
import Tippy from "@tippyjs/react";
import toastOptions from "../../../../../assets/constants/toast";
import { IconButton } from "@mui/material";
import CallMadeIcon from '@mui/icons-material/CallMade';
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const ConfigView = ({ setEnginesView, config, refresh, setRefresh }) => {

    const { token } = useContext(AuthContext);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données seront perdues.")) {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + 'engines/' + config.id, { headers: { Authorization: 'Bearer ' + token } })
                .then(() => {
                    toast.success('Configuration moteur supprimée', toastOptions);
                    setRefresh(refresh + 1);
                })
                .catch(err => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    }

    const popover = (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            <div className='user-options-item add' onClick={() => setEnginesView({ name: 'add' })}>
                <span>Ajouter une autre configuration</span>
            </div>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer cette configuration</span>
            </div>
        </div>
    );

    return (
        <div className='view-container engine-config-view'>
            <div className='flex-contained'>
                <div>
                    <div className='input-row-container'>
                        <span className="input-label engine">Moteur</span>
                        <CustomTag value={config.engineType} />
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label id">Code moteur</span>
                        <CustomTag value={config.engineCode} />
                    </div>
                </div>
                <div>
                    <div className='view-header'>
                        <div className='view-buttons'>
                            <button className='main-button blue-button edit' onClick={() => setEnginesView({ name: 'edit', params: config })}>Modifier</button>
                            <Tippy content={popover}
                                allowHTML={true}
                                placement={'bottom-end'}
                                offset={[0, 5]}
                                visible={popoverVisible}
                                onClickOutside={hide}
                                interactive={true}
                                appendTo={'parent'}>
                                <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                            </Tippy>
                        </div>
                    </div>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label id">Nom de l'hôtel</span>
                <CustomTag value={config.hotelName} />
            </div>
            <div className='input-row-container'>
                <span className="input-label link">URL moteur</span>
                <CustomTag value={config.engineUrl} />
                <a href={'https://' + config.engineUrl} target='_blank' rel="noreferrer" className='margin-left'>
                    <IconButton aria-label="delete" size="small">
                        <CallMadeIcon fontSize="small" />
                    </IconButton>
                </a>
            </div>
            <div className='input-row-container'>
                <span className="input-label euro">Taxe de séjour</span>
                <CustomTag value={config.stayTax ? (config.stayTax + ' €') : 'Non renseignée'} />
                <Tooltip title="Si le prix comprend une partie de la taxe de séjour mais pas sa totalité, renseigner la partie prise en compte dans le prix dans ce champ." arrow placement='top'>
                    <div className='margin-left'>
                        <IconButton aria-label="delete" size="small">
                            <HelpIcon fontSize="small" />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
            {config.engineType.includes('dedge') ? (
                <>
                    <div className='input-row-container'>
                        <span className="input-label euro">Taxe de séjour (2)</span>
                        <CustomTag value={config.dedgeConfig.customStayTax ? (config.dedgeConfig.customStayTax + ' €') : 'Non renseignée'} />
                        <Tooltip title="Si le prix comprend une partie de la taxe de séjour mais pas sa totalité, renseigner la partie prise en compte dans le prix dans ce champ." arrow placement='top'>
                            <div className='margin-left'>
                                <IconButton aria-label="delete" size="small">
                                    <HelpIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label link">URL Booking</span>
                        <CustomTag value={config.dedgeConfig.bookingUrl ? config.dedgeConfig.bookingUrl : 'Non renseignée'} />
                        {config.dedgeConfig.bookingUrl ? (
                            <a href={'https://' + config.dedgeConfig.bookingUrl} target='_blank' rel="noreferrer" className='margin-left'>
                                <IconButton aria-label="delete" size="small">
                                    <CallMadeIcon fontSize="small" />
                                </IconButton>
                            </a>
                        ) : null}
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label link">URL Expedia</span>
                        <CustomTag value={config.dedgeConfig.expediaUrl ? config.dedgeConfig.expediaUrl : 'Non renseignée'} />
                        {config.dedgeConfig.expediaUrl ? (
                            <a href={'https://' + config.dedgeConfig.expediaUrl} target='_blank' rel="noreferrer" className='margin-left'>
                                <IconButton aria-label="delete" size="small">
                                    <CallMadeIcon fontSize="small" />
                                </IconButton>
                            </a>
                        ) : null}
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label id">Groupe</span>
                        <CustomTag value={config.dedgeConfig.groupId ? ('Groupe ' + config.dedgeConfig.groupId) : 'Non renseigné'} />
                    </div>
                </>
            ) : (
                <>
                    <div className='input-row-container'>
                        <span className="input-label id">Préfixe moteur</span>
                        <CustomTag value={config.reservitConfig.enginePrefix ? (config.reservitConfig.enginePrefix) : 'Non renseigné'} />
                        <Tooltip title="Identifiant qui se trouve entre le /fo/booking et le code moteur dans l'URL du moteur" arrow placement='top'>
                            <div className='margin-left'>
                                <IconButton aria-label="delete" size="small">
                                    <HelpIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label id">Identifiant prix</span>
                        <CustomTag value={config.reservitConfig.rateId ? (config.reservitConfig.rateId) : 'Non renseigné'} />
                        <Tooltip title="Identifiant qui sera envoyé à Derbysoft par défaut sur l'API hotelRate (prendre un identifiant dans le BO Reservit de l'hôtel concerné)" arrow placement='top'>
                            <div className='margin-left'>
                                <IconButton aria-label="delete" size="small">
                                    <HelpIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    );
}

export default ConfigView;