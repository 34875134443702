import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import ProjectCard from "./ProjectCard";
import CustomTag from "../../custom/CustomTag";
import toastOptions from "../../../assets/constants/toast";
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import dayjs from "dayjs";

const ProjectList = ({ projectType = 'ongoing' }) => {

    const { token } = useContext(AuthContext);
    const [projects, setProjects] = useState(null);
    const [projectsLoaded, setProjectsLoaded] = useState(false);
    const [refreshData, setResfreshData] = useState(0);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    useEffect(() => {
        setProjectsLoaded(false);
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects/' + projectType, { headers: { Authorization: 'Bearer ' + token } })
            .then(response => {
                setProjects(response.data);
                console.log(response.data)

                setProjectsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setProjectsLoaded(true);
            });
    }, [refreshData, projectType, token]);


    const handleDailyPersist = (persistType) => {
        toast.promise(
            axios.get(process.env.REACT_APP_UPA_API_HOST + 'projects/' + persistType, { headers: { Authorization: 'Bearer ' + token } }),
            {
                pending: 'Mise à jour des projets',
                success: 'Projets mis à jour',
                error: 'Une erreur est survenue'
            }
            , toastOptions).then(() => {
                setResfreshData(refreshData + 1);
            });
    }

    const popover = (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            <div className='user-options-item update' onClick={() => handleDailyPersist('daily-persist')} >
                <span>Mettre à jour</span>
            </div>
            <div className='user-options-item update warning' onClick={() => handleDailyPersist('full-persist')} >
                <span>Mettre à jour  toute la base</span>
            </div>
        </div>
    );

    return projectsLoaded ? (
        <div className='page-content'>
            <div className='list-header'>
                <div className='header-title projects'>
                    <h1>Projets {projectType === 'ongoing' ? 'en cours' : 'internes'}</h1>
                    <CustomTag value={projects.length} className='margin-left' />
                </div>
                <div className='header-buttons'>
                    <div className='header-info'>
                        <span>Dernière MAJ : {dayjs(projects[0].createdAt).format('DD/MM à HH:mm')}</span>
                    </div>
                    <Tippy content={popover}
                        allowHTML={true}
                        placement={'bottom-end'}
                        offset={[0, 5]}
                        visible={popoverVisible}
                        onClickOutside={hide}
                        interactive={true}
                        appendTo={'parent'}>
                        <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className="project-container">
                {projects.map((project, index) => (
                    <ProjectCard project={project.data} key={index} />
                ))}
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default ProjectList;