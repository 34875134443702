import React from "react";
import {Route, Routes} from "react-router-dom";
import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";
import User from "./UserView";
import UserList from "./UserList";
import PermissionsGate from "../PermissionsGate";

const Users = () => {
    return (
        <PermissionsGate module='users'>
            <Routes>
                <Route path="new" element={<UserAdd />} />
                <Route path=":userId/edit" element={<UserEdit />} />
                <Route path=":userId" element={<User />} />
                <Route path="*" element={<UserList />} />
            </Routes>
        </PermissionsGate>
    )
}

export default Users;