import React, {useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import CustomSearchInput from "../../custom/CustomSearchInput";
import {CircularProgress, Switch} from "@mui/material";

const UserEdit = () => {

    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    if(response.data.status === "archived") {
                        navigate(-1);
                    } else {
                        setUser(response.data);
                    }
                } else {
                    toast.error('Utilisateur inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'users/roles', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setRoles(response.data);
                } else {
                    toast.error('Utilisateur inconnu', toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, userId, navigate]);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { user }, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("L'utilisateur a bien été modifié", toastOptions);
                navigate(-1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setButtonLoading(false);
            });
    }

    return user && roles ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{user.firstName + ' ' + user.lastName}</h1>
                    <span className='view-id'>#{user.id}</span>
                </div>
                <div className='view-buttons'>
                    <button onClick={() => navigate(-1)} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button validate margin-left' onClick={handleUpdate} disabled={buttonLoading}>{ buttonLoading ? <CircularProgress size={20} className="white-loader" /> : 'Sauvegarder' }</button>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Prénom <span className='required'>*</span></span>
                <div className='input-container without-icon'>
                    <input value={user.firstName} className="classic-input" placeholder='Entrez un prénom' onChange={(e) => {
                        setUser((user) => { return { ...user, firstName:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Nom <span className='required'>*</span></span>
                <div className='input-container without-icon'>
                    <input value={user.lastName} className="classic-input" placeholder='Entrez un nom' onChange={(e) => {
                        setUser((user) => { return { ...user, lastName:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label email">Email <span className='required'>*</span></span>
                <div className='input-container without-icon'>
                    <input value={user.email} className="classic-input" placeholder='Entrez une adresse email' onChange={(e) => {
                        setUser((user) => { return { ...user, email:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className='input-label role'>Rôle <span className='required'>*</span></span>
                <div className='input-container'>
                    <CustomSearchInput placeholder='Rechercher un rôle'
                                       entity={roles}
                                       value={roles.find(role => role.id === user.roleId)}
                                       onChange={(newValue) => { setUser((user) => { return { ...user, roleId: newValue.value }})}}
                    />
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label role">Gestion des congés</span>
                <div className='input-container without-icon switch'>
                    <Switch checked={user.holidayActive} onChange={(e) => setUser({...user, holidayActive: e.target.checked, holidayStartingBalance: e.target.checked ? user.holidayStartingBalance : 0 })} />
                </div>
            </div>
            { user.holidayActive ? (
                <div className='input-row-container'>
                    <span className="input-label role">Solde de départ</span>
                    <input type="number" className="classic-input" value={user.holidayStartingBalance} min={0} step={0.01} onChange={(e) => setUser({...user, holidayStartingBalance: e.target.value})} placeholder="Solde" />
                </div>
            ) : null }
        </div>
    ) : (<div className='loader-container'><CircularProgress size={30} /></div>)
}

export default UserEdit;