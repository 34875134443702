import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import Scraper from "./Scraper";
import Translator from "./Translator";
import Souscription from "./Souscription";
import Facturation from "./Facturation";

const Chatbot = () => {

    const moduleName = 'chatbot';

    return (
        <Routes>
            <Route path="/extract" element={<PermissionsGate module={moduleName} submodule='extract'><Scraper /></PermissionsGate>} />
            <Route path="/translate" element={<PermissionsGate module={moduleName} submodule='translate'><Translator /></PermissionsGate>} />
            <Route path="/souscription" element={<PermissionsGate module={moduleName} submodule='souscription'><Souscription /></PermissionsGate>} />
            <Route path="/facturation" element={<PermissionsGate module={moduleName} submodule='facturation'><Facturation /></PermissionsGate>} />
            <Route path="*" element={<Navigate to='extract' />} />
        </Routes>
    );
}

export default Chatbot;
