import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import CustomList from "../../../custom/CustomList";
import CustomTag from "../../../custom/CustomTag";
import BillCreator from "./BillCreator";
import { Link } from "react-router-dom";
import CsvImporter from "./CsvImporter";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";

const Billing = () => {

    const [data, setData] = useState();
    const [csvData, setCsvData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [refreshData, setRefreshData] = useState(0);
    const { token } = useContext(AuthContext);

    const columns = [
        {
            Header: (<span>Hôtel <small>(Code moteur - TVA)</small></span>),
            id: 'hotel',
            icon: 'hotel',
            Cell: row => (<div className='billing-metas-amount'><Link to={'/admin/inventaire/clients/' + row.row.original.contact.id} >{row.row.original.contact.data.customer_name}</Link><CustomTag className={row.row.original.engineType} value={row.row.original.engineCode} /><CustomTag value={row.row.original.taxId} /></div>)
        },
        {
            Header: 'Réservations',
            accessor: 'bookingsAmount',
            icon: 'euro',
            Cell: ({ cell: { value } }) => (<span>{formatAmount(value) + ' €'}</span>)
        },
        {
            Header: 'Résas. métas',
            icon: 'euro',
            Cell: row => (<div className='billing-metas-amount'><span>{formatAmount(row.row.original.metasAmount) + ' €'}</span><CustomTag value={parseInt(row.row.original.metaPercentage) + '%'} /></div>)
        },
        {
            Header: 'Estimation',
            icon: 'percentage',
            Cell: row => (<CustomTag className={row.row.original.estimatedMinAmount >= 1000 ? 'debit' : 'credit'} value={formatAmount(row.row.original.estimatedMinAmount) + " - " + formatAmount(row.row.original.estimatedMaxAmount) + " €"} />)
        },
        {
            Header: 'Montant à facturer TTC',
            icon: 'percentage',
            Cell: row => row.row.original.billingCost ? (<CustomTag className="credit" value={formatAmount(row.row.original.billingCost, 2) + " €"} />) : '0,00 €'
        }
    ];

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'metas/billing', { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => {
                console.log(response.data);
                setData(response.data.sort((a, b) => a.contact.data.customer_name.localeCompare(b.contact.data.customer_name)));
                setIsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.message, toastOptions);
                setIsLoaded(true);
            });
    }, [refreshData, token]);

    useEffect(() => {
        if(csvData) {
            setData(data => data.map((row) => {
                    let billingCost = csvData.find(csvRow => csvRow['Code moteur'] === row.engineCode);
                    billingCost = billingCost !== "" ? parseFloat(billingCost["Montant à facturer TTC"]) : 0;
                    return { ...row, billingCost: billingCost }
                }
            ));
            console.log(data.map((row) => {
                    let billingCost = csvData.find(csvRow => csvRow['Code moteur'] === row.engineCode);
                    billingCost = billingCost ? parseFloat(billingCost["Montant à facturer TTC"]) : 0;
                    return { ...row, billingCost: billingCost }
                }
            ));
        }
    }, [csvData]);

    const formatAmount = (amount, digits = 0) => {
        return Number(amount).toLocaleString('fr-FR', { maximumFractionDigits: digits, minimumFractionDigits: digits });
    }

    const downloadTemplate = () => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'metas/billing/template', { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'metas_billing_template.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.message, toastOptions);
            });
    }

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title pennylane">
                    <h1>Facturation métas</h1>
                    <CustomTag value={data.length} />
                </div>
                <div className="header-buttons">
                    <button className='main-button download' onClick={downloadTemplate}>Télécharger le template CSV</button>
                    <BillCreator transactions={data} setTransactions={setData} refreshData={refreshData} setRefreshData={setRefreshData} />
                </div>
            </div>
            <p className="waiting-order-warning no-margin-bottom">
                <span>Saisie des montants pour la facturation Métas pour le mois de <strong>{dayjs().format('MMMM YYYY')}</strong>. Ils seront importés dans Pennylane et Gocardless à la date du <strong>{dayjs().endOf('month').format('DD MMMM YYYY')}</strong>.</span>
            </p>
            <CsvImporter data={csvData} setData={setCsvData} label="Déposez le fichier CSV complété" />
            <div className="tabs-container">
                <CustomList entity={data} isSelectable={false} isFetchable={false} isClickable={false} columns={columns.filter(column => !column.hide)} setData={setData} />
            </div>
        </div>
    ) : <div className="loader-container"><CircularProgress size={30} /></div>
}

export default Billing;